import tinycolor from 'tinycolor2';

import theme from '../theme/komodo-mui-theme';

export const getRandomFromArray = (colors: string[]) =>
  colors[Math.floor(Math.random() * colors.length)];

/**
 * Retrieves the computed value of a CSS variable if the input color is a CSS
 * variable. If the input color is not a CSS variable or if the computed value
 * is not found, it returns the original input color.
 */
const getComputedColor = (color: string | undefined) => {
  if (typeof color === 'string' && /^var\(--[^)]+\)$/.test(color)) {
    const cssVariable = color.slice(4, -1).trim(); // extracts "--colors-variable-name"
    const computedColor = getComputedStyle(document.documentElement)
      .getPropertyValue(cssVariable)
      .trim();

    if (computedColor) return computedColor;
  }
  return color;
};

export const isDarkColor = (color: string | undefined) => {
  const computedColor = getComputedColor(color);
  return computedColor ? tinycolor(computedColor).isDark() : undefined;
};

export const getTextColor = (backgroundColor: string) =>
  isDarkColor(backgroundColor) ? 'text-type-secondary' : 'text-type-primary';

export const colorOptionsFromPalette = Object.values(theme.palette.chartsThemes);
export const getNewPickerColor = (usedColors: string[]) => {
  let colors = colorOptionsFromPalette.filter((c) => !usedColors.includes(c));
  if (colors.length === 0) {
    colors = colorOptionsFromPalette;
  }
  return getRandomFromArray(colors);
};
