// This file contains project permissions descriptions generators;
// IMPORTANT! The order of the descriptions drives the desired permissions display order.

import { TermKey } from '../../../../api/gqlEnums';
import { PermissionResource } from '../../../../generated/graphql';

export type PermissionDisplayData = {
  resource: PermissionResource;
  label: string;
  description: string;
  dependencies?: string;
  note?: string;
};

export const getCostPermissionDisplayDataSorted = (
  termStore: TermStore,
  hasTrades: boolean,
  hasOwnerCostFeature: boolean,
  hasOwnerContingencyFeature: boolean
): PermissionDisplayData[] => {
  const budgetTermPlural = termStore.pluralSentenceCase(TermKey.TARGET);
  const budgetTermSingular = termStore.sentenceCase(TermKey.TARGET);
  const estimateTermPlural = termStore.pluralSentenceCase(TermKey.ESTIMATE);
  const markupTermSingular = termStore.sentenceCase(TermKey.MARKUP);

  let ownerCostDescription = '';
  if (hasOwnerContingencyFeature) {
    ownerCostDescription = `Control edit access to Owner Costs, Owner Contingencies, and Owner Allowances in Milestone ${estimateTermPlural}, ${budgetTermPlural}, and Items/Options, and ability to control if Totals include Owner Costs with the Project Costs Display.`;
  } else if (hasOwnerCostFeature) {
    ownerCostDescription = `Control edit access to owner costs in Milestone ${estimateTermPlural}, ${budgetTermPlural}, and Items/Options, and ability to control if Totals include Owner Costs with the Project Costs Display`;
  } else {
    ownerCostDescription = `Control edit access to owner costs in Milestone ${estimateTermPlural}, ${budgetTermPlural}, and Items/Options.`;
  }

  const costPermissionsDescriptions = [
    {
      resource: PermissionResource.MILESTONE_LINES,
      label: `Milestone cost details`,
      description: `Control edit access to detailed lines of Milestone ${estimateTermPlural} & ${budgetTermPlural}.`,
    },
    {
      resource: PermissionResource.MILESTONE_BUDGET,
      label: `Milestone ${budgetTermSingular} cost details`,
      description: `Control edit access to detailed lines of ${budgetTermPlural}.`,
      dependencies:
        'More restrictive Milestone cost details selected above will override this setting.',
    },
    hasTrades
      ? {
          resource: PermissionResource.ITEM_IN_TRADE_LINES,
          label: `Item/option cost details (with category permissions)`,
          description:
            'Control edit access to detailed cost lines on Items & Options within categories that match the users’ assigned categories.',
        }
      : {
          resource: PermissionResource.ITEM_IN_TRADE_LINES,
          label: `Item/option cost details`,
          description: 'Control edit access to detailed lines of Items & Options.',
        },
    {
      resource: PermissionResource.ESTIMATE_COST_SUBTOTALS,
      label: `${markupTermSingular} subtotals`,
      description: `Control edit access to subtotals of the ${markupTermSingular} section in ${estimateTermPlural}, ${budgetTermPlural} and Items/Options.`,
      note: `${markupTermSingular} display options selection will affect this control in reports.`,
      dependencies: 'Restricted detailed line access selected above will override this setting.',
    },
    {
      resource: PermissionResource.MARKUPS,
      label: `${termStore.sentenceCase(TermKey.MARKUP)} details`,
      description: `Control edit access to detailed lines of the ${markupTermSingular} section in ${estimateTermPlural}, ${budgetTermPlural}, Items/Options (includes ${markupTermSingular}, contingencies and allowances).`,
      note: `${markupTermSingular} display permissions and selections will affect this control in reports.`,
      dependencies:
        'Restricted detailed line and subtotal access selected above will override this setting.',
    },
    {
      resource: PermissionResource.ITEM_TEMPLATE,
      label: 'Default Item Template (Settings)',
      description: 'Control edit access to the Default Item Template.',
    },
    {
      resource: PermissionResource.OWNER_COSTS,
      label: 'Owner costs',
      description: ownerCostDescription,
    },
  ];

  if (hasTrades)
    costPermissionsDescriptions.splice(3, 0, {
      resource: PermissionResource.ITEM_OUT_OF_TRADE_LINES,
      label: `Item/option cost details (without category permissions)`,
      description:
        'Control edit access to detailed cost lines on Items & Options within categories that do not match the users’ assigned categories.',
    });

  return costPermissionsDescriptions;
};

export const getProjectPermissionDisplayDataSorted = () => {
  const projectSettings = [
    {
      resource: PermissionResource.PROJECT_DETAILS,
      label: 'Project details',
      description:
        'Control edit access to Project details, such as: Project Name, Status, Type, Location, Description, Print Header, etc.',
    },
    {
      resource: PermissionResource.CATEGORIES_AND_TAGS,
      label: 'Categorizations',
      description: 'Control edit access to project Categorizations.',
    },
    {
      resource: PermissionResource.PROJECT_UNITS,
      label: 'Project Units of Measure',
      description: 'Control edit access to Units of Measure on a project, such as GSF or NSF.',
    },
    {
      resource: PermissionResource.PROJECT_CURRENCY,
      label: 'Project currency / Rounding display',
      description:
        'Control edit access to Project Currency and Rounding Display for project costs.',
    },
    {
      resource: PermissionResource.PROJECT_TERMINOLOGY,
      label: 'Project terminology',
      description: 'Control edit access to Project terminology.',
    },
  ];

  const projectAdministration = [
    {
      resource: PermissionResource.COLLABORATORS,
      label: 'Team/Teammates',
      description:
        'Control edit access to Teammates and their Project Responsibilities, including changing role.',
    },
    {
      resource: PermissionResource.PERMISSION_TEMPLATES,
      label: 'Project Roles, Permissions & Previews',
      description:
        'Control access to Project Role Templates, Permissions and Previews (by User & Role).',
    },
    {
      resource: PermissionResource.TIMELINE,
      label: 'Timeline',
      description: 'Control edit access of the Project Timeline.',
    },
  ];

  const reportPermissions = [
    {
      resource: PermissionResource.SHARED_USER_REPORTS,
      label: 'Shared reports',
      description: 'Control access to edit and share saved reports with the project team.',
    },
    {
      resource: PermissionResource.REPORT_DISTRIBUTION,
      label: 'Report Distribution',
      description: 'Control access to send or schedule an email of a shared report as a .PDF.',
    },
    {
      resource: PermissionResource.USER_REPORT_NOTES,
      label: 'Report Notes',
      description: 'Control ability to delete Notes on select reports.',
    },
  ];

  return {
    projectSettings,
    projectAdministration,
    reportPermissions,
  };
};

export const getMilestonePermissionDisplayDataSorted = (termStore: TermStore) => {
  const budgetTermPlural = termStore.pluralSentenceCase(TermKey.TARGET);
  const estimateTermPlural = termStore.pluralSentenceCase(TermKey.ESTIMATE);

  return [
    {
      resource: PermissionResource.MILESTONES,
      label: 'Milestones',
      description: 'Control access to Milestones. Control access to change the active Milestone.',
      note: 'This permission enables changes to Milestone Name, Start Date, and Design Phase. All other Milestone details follow the permissions configured below.',
    },
    {
      resource: PermissionResource.DRAFT_MILESTONE,
      label: 'Draft Milestones (created by others)',
      description: 'Control access to Milestones created by others.',
    },
    {
      resource: PermissionResource.METRICS,
      label: 'Milestone Metrics',
      description: 'Control access to Metric quantities on milestones, such as 10,000 GSF.',
    },
    {
      resource: PermissionResource.DRAFT_ESTIMATE,
      label: `Draft ${estimateTermPlural} and ${budgetTermPlural} (created by others)`,
      description: `Control access to draft ${estimateTermPlural} and ${budgetTermPlural} created by others.`,
    },
    {
      resource: PermissionResource.MILESTONE_ATTACHMENTS,
      label: 'Milestone Attachments',
      description: 'Control access to Milestone Attachments.',
    },
  ];
};

export const getItemPermissionDisplayDataSorted = (
  hasContingencyAddsFeature: boolean,
  hasOwnerCostContingencyFeature: boolean
) => {
  return [
    {
      resource: PermissionResource.ITEM_DETAILS,
      label: 'Items, Item import and Options',
      description: 'Control access to create, edit, delete and import Items/Options.',
      note: 'This permission enables changes to Name and Description. All other Items and Options details follow the permissions configured below.',
    },
    {
      resource: PermissionResource.SHARE_ITEMS_SCENARIOS,
      label: 'Share draft items/scenarios',
      description: 'Control access to share draft Items/Scenarios with others.',
    },
    {
      resource: PermissionResource.PUBLISH_ITEMS_SCENARIOS,
      label: 'Publish draft items/scenarios',
      description: 'Control access to publish draft Items/Scenarios with others.',
    },
    {
      resource: PermissionResource.ITEM_NUMBER,
      label: 'Item Number',
      description: 'Control access to change an Item’s/Option’s Number.',
    },
    {
      resource: PermissionResource.ITEM_STATUS,
      label: 'Item Status',
      description: 'Control access to change an Item’s/Option’s Status.',
    },
    {
      resource: PermissionResource.ITEM_ASSIGNEES,
      label: 'Assignees and Due Dates',
      description: 'Control edit access to change an Item’s/Option’s Assignee and Due Date.',
    },
    {
      resource: PermissionResource.DRAW_EVENTS_VIEW,
      label: hasContingencyAddsFeature
        ? 'Adjustment Activity (Contingencies & Allowances)'
        : 'Draw activity (Contingencies & Allowances)',
      description: hasOwnerCostContingencyFeature
        ? 'Control access to Contingency and Allowance adjustment activity in the Item’s/Option’s history (doesn’t include Owner Contingencies & Allowances)'
        : `Control access to Contingency and Allowance ${hasContingencyAddsFeature ? 'adjustment' : 'draw'} activity in an Item’s/Option’s history.`,
    },
    {
      resource: PermissionResource.ITEM_MILESTONE_AND_MEETINGS,
      label: 'Milestones and Events',
      description: 'Control edit access to an Item’s/Option’s Milestone or Events.',
    },
    {
      resource: PermissionResource.ITEM_CATEGORIES,
      label: 'Categories',
      description: 'Control edit access to an Item’s/Option’s Categories.',
    },
    {
      resource: PermissionResource.SCHEDULE_IMPACT,
      label: 'Schedule impact',
      description: 'Control edit access to an Item’s/Option’s Schedule Impact.',
    },
    {
      resource: PermissionResource.ITEM_ATTACHMENTS,
      label: 'Attachments',
      description: 'Control access to an Item’s/Option’s Attachments.',
    },
    {
      resource: PermissionResource.ITEM_INTEGRATIONS,
      label: 'Item Integrations',
      description: 'Control access to an Item’s/Option’s Integrations.',
    },
    {
      resource: PermissionResource.ITEM_COMMENTS,
      label: 'Comments',
      description: 'Control access to an Item’s/Option’s comments.',
    },
  ];
};

export const riskPermissionDisplayData = [
  {
    resource: PermissionResource.RISK_DETAILS,
    label: 'Project risks',
    description: 'Control access to create, edit and delete project risks.',
  },
];

export const getMarkupModePermissionLabel = (resource: string, t: TermStore) => {
  switch (resource) {
    case PermissionResource.ALLOCATED_MARKUPS_VIEW:
      return `${t.sentenceCase(TermKey.MARKUP)} allocated`;
    case PermissionResource.SEPARATED_MARKUPS_VIEW:
      return `${t.sentenceCase(TermKey.MARKUP)} separated`;
    case PermissionResource.NO_MARKUPS_VIEW:
      return `${t.sentenceCase(TermKey.DIRECT_COST)} only`;
    default:
      return resource;
  }
};

export const getMarkupModeDescription = (resource: string, markupsTerm: string) => {
  switch (resource) {
    case PermissionResource.ALLOCATED_MARKUPS_VIEW:
      return `Allocate selected ${markupsTerm} pro-rata. All unallocated ${markupsTerm} will be visible in reports.`;
    case PermissionResource.SEPARATED_MARKUPS_VIEW:
      return `Do not allocate ${markupsTerm}.`;
    case PermissionResource.NO_MARKUPS_VIEW:
      return `${markupsTerm} are not displayed or included in totals.`;
    default:
      return resource;
  }
};
