import { makeVar } from '@apollo/client';

import { getReactiveLocal, setReactiveLocal } from '../../api/apollo/reactiveVars';

import { ProjectsCostModes } from './costModeLogic';

const LOCAL_STORAGE_COST_MODES = 'PROJECTS_COST_MODES';

const fetchLocalStorageCostModes = () =>
  getReactiveLocal<ProjectsCostModes>(LOCAL_STORAGE_COST_MODES, {});

export const projectsCostModesVar = makeVar<ProjectsCostModes>(fetchLocalStorageCostModes());

export const setNewProjectCostMode = (
  projectID: UUID,
  costMode: CostMode,
  projectsCostModes: ProjectsCostModes
) => {
  const newProjectsCostModes = {
    ...projectsCostModes,
    [projectID]: costMode,
  };
  setReactiveLocal(projectsCostModesVar, LOCAL_STORAGE_COST_MODES, newProjectsCostModes);
};
