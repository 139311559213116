import { commonUserFields, gql, itemLinkFields } from '../../../../api/graphqlFragments';

export const riskQuery = gql`
  query Risk($riskID: UUID!, $costDisplay: CostDisplay!) {
    risk(riskID: $riskID, costDisplay: $costDisplay) {
      id
      projectID
      type
      number
      name
      description
      descriptionStyled
      responsePlan
      responsePlanStyled
      status
      riskScore
      impact
      likelihood
      assignee {
        ...commonUserFields
      }
      updatedBy {
        ...commonUserFields
      }
      createdBy {
        ...commonUserFields
      }
      updatedAt
      createdAt
      romCost
      linkedItemIDs
    }
  }
  ${commonUserFields}
`;

export const riskHistoryQuery = gql`
  query RiskHistory($riskID: UUID!, $costDisplay: CostDisplay!, $filters: RiskHistoryFilters!) {
    riskHistory(riskID: $riskID, costDisplay: $costDisplay, filters: $filters) {
      type
      createdAt
      createdBy {
        ...commonUserFields
      }
      content {
        addedItems {
          ...itemLinkFields
        }
        removedItems {
          ...itemLinkFields
        }
      }
      risk {
        type
        number
        name
        description
        descriptionStyled
        responsePlan
        responsePlanStyled
        status
        riskScore
        impact
        likelihood
        assignee {
          ...commonUserFields
        }
        romCost
        linkedItemIDs
      }
    }
  }
  ${commonUserFields}
  ${itemLinkFields}
`;

export const deprecateRiskMutation = gql`
  mutation DeprecateRisk($riskID: UUID!) {
    deprecateRisk(riskID: $riskID)
  }
`;

export const setRiskNameMutation = gql`
  mutation SetRiskName($riskID: UUID!, $name: String!) {
    setRiskName(riskID: $riskID, name: $name)
  }
`;

export const setRiskImpactMutation = gql`
  mutation SetRiskImpact($riskID: UUID!, $impact: Int!) {
    setRiskImpact(riskID: $riskID, impact: $impact)
  }
`;

export const setRiskLikelihoodMutation = gql`
  mutation SetRiskLikelihood($riskID: UUID!, $likelihood: Int!) {
    setRiskLikelihood(riskID: $riskID, likelihood: $likelihood)
  }
`;

export const setRiskDescriptionMutation = gql`
  mutation SetRiskDescription($riskID: UUID!, $description: String!, $descriptionStyled: String!) {
    setRiskDescription(
      riskID: $riskID
      description: $description
      descriptionStyled: $descriptionStyled
    )
  }
`;

export const setRiskResponsePlanMutation = gql`
  mutation SetRiskResponsePlan(
    $riskID: UUID!
    $responsePlan: String!
    $responsePlanStyled: String!
  ) {
    setRiskResponsePlan(
      riskID: $riskID
      responsePlan: $responsePlan
      responsePlanStyled: $responsePlanStyled
    )
  }
`;

export const setRiskStatusMutation = gql`
  mutation SetRiskStatus($riskID: UUID!, $status: RiskStatus!) {
    setRiskStatus(riskID: $riskID, status: $status)
  }
`;

export const setRiskAssigneeMutation = gql`
  mutation SetRiskAssignee($riskID: UUID!, $assigneeID: UUID) {
    setRiskAssignee(riskID: $riskID, assigneeID: $assigneeID)
  }
`;

export const setRiskROMCostMutation = gql`
  mutation SetRiskROMCost($riskID: UUID!, $romCost: USCents) {
    setRiskROMCost(riskID: $riskID, romCost: $romCost)
  }
`;

export const addRiskItemMutation = gql`
  mutation AddRiskItem($riskID: UUID!, $itemID: UUID!) {
    addRiskItem(riskID: $riskID, itemID: $itemID)
  }
`;

export const removeRiskItemMutation = gql`
  mutation RemoveRiskItem($riskID: UUID!, $itemID: UUID!) {
    removeRiskItem(riskID: $riskID, itemID: $itemID)
  }
`;
