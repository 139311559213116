/**
 * Strict unions are useful if you want to create a type that is one of multiple
 * types that you eventually want to narrow down to a single type.
 */
type UnionKeys<T> = T extends T ? keyof T : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
type StrictUnionHelper<T, TAll> = T extends any
  ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>>
  : never;

export type StrictUnion<T> = StrictUnionHelper<T, T>;

/**
 * Generally used to let you easily filter out nullables from arrays.
 * Eg, const myArray: (T | undefined)[] = [];
 *     myArray.filter(isNonNullable) // type of the array is now T[]
 */
export function isNonNullable<T>(value: T | undefined | null): value is T {
  return value !== null && value !== undefined;
}

export type PartialNullable<T> = { [P in keyof T]?: T[P] | undefined | null };

/**
 * A type-guard used to narrow a string to an enum.
 * @param theEnum The enum itself
 * @param theValue The string value to check
 * @returns Identity boolean about whether `theValue` is a member of the enum.
 */
export const isEnumValue = <T extends Record<string, string>>(
  theEnum: T, // weird grammar bc `enum` is a reserved word :shrug:
  theValue: string
): theValue is T[keyof T] => {
  return Object.values(theEnum).includes(theValue);
};

/**
 * A type-guard used to narrow a string to an enum.
 * @param theEnum The enum itself
 * @param theValue The string value to check
 * @returns The value if it is a member of the enum, otherwise null.
 */
// ts-prune-ignore-next
export const asEnumValueOrNull = <T extends Record<string, string>>(
  theEnum: T,
  theValue: string | null | undefined
): T[keyof T] | null => {
  if (theValue === null || theValue === undefined) return null;
  return isEnumValue(theEnum, theValue) ? theValue : null;
};
