import { useParams } from 'react-router-dom';
import { isUUID } from 'validator';

import {
  ProjectCollaboratorQuery,
  ProjectCollaboratorQueryVariables,
} from '../../../generated/graphql';
import { useQuery } from '../../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../../hooks/usePolicyOnFirstMount';

import { projectCollaboratorQuery } from './queries';

export default function useProjectCollaborator(customProjectID?: UUID, mountPolicy?: MountPolicy) {
  const currentProjectID = useParams().projectId;
  const projectID = customProjectID ?? currentProjectID;

  const { data, loading, previousData } = useQuery<
    ProjectCollaboratorQuery,
    ProjectCollaboratorQueryVariables
  >(
    projectCollaboratorQuery,
    {
      variables: { projectID },
      skip: !projectID || !isUUID(projectID),
    },
    mountPolicy
  );
  return { collaborator: data?.currentCollaborator ?? previousData?.currentCollaborator, loading };
}
