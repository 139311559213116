import { Auth0Provider } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import App from './components/App';
import JoinAuthProvider, { useJoinAuth } from './components/JoinAuthProvider';
import LoginPage from './components/login/LoginPage';
import { AUTHORIZE, AUTH_REDIRECT } from './constants';
import './api/gql';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { tokenUtil } from './utilities/authUtils';
import { initializeSentry } from './utilities/sentry';

// Disable analytics if specified OR if we are on a print page.
if (
  !process.env.REACT_APP_DISABLE_ANALYTICS &&
  window.location.pathname.indexOf('/print/') === -1
) {
  let env;
  const fullUrl = window.location.href;
  if (fullUrl.includes('staging')) {
    env = 'staging';
  } else if (fullUrl.includes('app')) {
    env = 'production';
  }
  datadogRum.init({
    applicationId: '71b9b620-be13-4ea9-b0b3-4b49acc2b57d',
    clientToken: 'pubf2232997610d5af697b23b207b0d3836',
    site: 'datadoghq.com',
    service: 'komodo-ui',
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [
      (url) => url.startsWith('https://app.join.build'),
      (url) => url.startsWith('https://api.join.build'),
      (url) => url.startsWith('https://staging.join.build'),
      (url) => url.startsWith('https://staging-api.join.build'),
    ],
  });

  new PerformanceObserver((entryList) => {
    entryList
      .getEntriesByName('first-contentful-paint')
      .forEach((entry) => datadogRum.addTiming('first_contentful_paint', entry.startTime));
  }).observe({ type: 'paint' });
}

const AppAuth = () => {
  const auth = useJoinAuth();
  tokenUtil.setGetAccessTokenSilentlyFunc(auth.getAccessTokenSilently);

  if (auth.isLoading) return <div />;

  const authenticated = auth.isAuthenticated && auth.loggedIn;
  const { pathname, search } = window.location;
  if (!authenticated) {
    // We will redirect the user off the page, so we need to preserve where they want to go after authentication
    if (pathname !== '/') sessionStorage.setItem(AUTH_REDIRECT, `${pathname}${search}`);
    return <LoginPage />;
  }

  // Wait till user is authenticated before setting user context for datadog tracking
  if (!process.env.REACT_APP_DISABLE_ANALYTICS) {
    datadogRum.setUser({
      id: auth.joinUser?.id,
      email: auth.joinUser?.email,
      name: auth.joinUser?.name,
      createdAt: auth.joinUser?.createdAt,
      didSetup: auth.joinUser?.didSetup,
      emailVerificationHashes: auth.joinUser?.emailVerificationHashes,
      isEmailVerified: auth.joinUser?.isEmailVerified,
      jobTitle: auth.joinUser?.jobTitle,
      phone: auth.joinUser?.phone,
      picture: auth.joinUser?.picture,
      resentEmailVerificationAt: auth.joinUser?.resentEmailVerificationAt,
      signupAt: auth.joinUser?.signupAt,
      status: auth.joinUser?.status,
      updatedAt: auth.joinUser?.updatedAt,
      releaseTag: process.env.REACT_APP_RELEASE_TAG,
    });
  }

  return <App />;
};

/* Staging credentials used to test in Auth0 Staging tenant
        domain="joincad-staging.us.auth0.com"
        clientId="Y4GrtwjJ2LPvLCzbW9kpBOnKqDjdDFq6"
        redirectUri={window.location.origin}
        scope="openid profile email read:rules read:current_user"
        skipRedirectCallback={window.location.pathname.includes(`/${AUTHORIZE}/`)} // This is important for authorizing connections
        audience="https://api.joincad.com"
        useRefreshTokens
*/

const renderApp = () =>
  createRoot(document.getElementById('root')!).render(
    <Sentry.ErrorBoundary>
      <Auth0Provider
        audience="https://api.joincad.com"
        clientId="K5Malw8PinzOBEn45OfGQsIYpxsvnc6j"
        domain="login.join.build"
        redirectUri={window.location.origin}
        scope="openid profile email read:rules read:current_user"
        skipRedirectCallback={window.location.pathname.includes(`/${AUTHORIZE}/`)} // This is important for authorizing connections
        useRefreshTokens
      >
        <JoinAuthProvider>
          <AppAuth />
        </JoinAuthProvider>
      </Auth0Provider>
    </Sentry.ErrorBoundary>
  );

initializeSentry();
// Call the unregister function to remove any existing service worker
// TODO: Remove after we're fairly confident it's been removed for everyone
unregisterServiceWorker();
renderApp();
