import { ScheduleImpactType, TimelineActivityType } from './api/gqlEnumsBe';
import { Scale } from './enums';
import { CostType } from './generated/graphql';

export const JOIN_SUPPORT_EMAIL = 'support@join.build';

export const ACCEPTED = 'ACCEPTED';
export const PENDING = 'PENDING';
export const PENDING_ADDS = 'PENDING_ADDS';
export const PENDING_DEDUCTS = 'PENDING_DEDUCTS';
export const REJECTED = 'REJECTED';
export const INCORPORATED = 'INCORPORATED';
export const NOT_APPLICABLE = 'NOTAPPLICABLE';
export const NOT_CHOSEN = 'NOTCHOSEN';
export const STATE_LIST_ARRAY = [
  NOT_APPLICABLE,
  REJECTED,
  PENDING,
  ACCEPTED,
  INCORPORATED,
  NOT_CHOSEN,
];
export const STATE_LIST_HOVER_SELECTOR_ARRAY = [
  ACCEPTED,
  PENDING,
  REJECTED,
  NOT_APPLICABLE,
  INCORPORATED,
  NOT_CHOSEN,
];

export const TRUE = 'true';
export const FALSE = 'false';

export const RUNNING = 'RUNNING';
export const TARGET = 'TARGET';

export const EDIT = 'edit';
export const CREATE = 'add';
export const DELETE = 'delete';

export const ADD = 'ADD';
export const REMOVE = 'REMOVE';

export const VIEW_ONLY = 'VIEW_ONLY';

export const SEPARATED_COST = 'SEPARATED_COST';

export const USER_REPORT_VAR = 'USER_REPORT_VAR';

export const TIMELINE = 'timeline';

export const DEFAULT_SELECTION = 'DEFAULT_SELECTION';
export const CLEAR_MEETINGS = 'CLEAR_MEETINGS';

export const ITEM_DETAILS = 'ITEM_DETAILS';
export const ITEM_CATEGORIES = 'ITEM_CATEGORIES';

export const UNASSIGNED = 'Unassigned';
export const ADD_NEW_EMPLOYEE = 'Add New Employee';

// ITEM TYPES
export const ITEM = 'ITEM';
export const OPTION = 'OPTION';
export const ITEM_WITH_OPTIONS = 'ITEM_WITH_OPTIONS';

// ITEM EVENT TYPES
export const ITEM_EVENT_ATTACH_OPTION = 'ATTACH_OPTION';
export const ITEM_EVENT_DETACH_OPTION = 'DETACH_OPTION';
export const ITEM_EVENT_CREATE_OPTION = 'CREATE_OPTION';
export const ITEM_DRAW_UPDATE = 'ITEM_DRAW_UPDATE';

export const REMOVED_DESCRIPTION = 'removed description';

export const SCHEDULE_IMPACT_TYPE_NAMES = {
  [ScheduleImpactType.TBD]: 'To be determined',
  [ScheduleImpactType.NA]: 'None / Not applicable',
  [ScheduleImpactType.DAYS]: 'Schedule Impacted',
};

export const TIMELINE_TYPE_NAMES = {
  [TimelineActivityType.EVENT]: 'Event',
  [TimelineActivityType.PHASE]: 'Phase',
  [TimelineActivityType.MILESTONE]: 'Milestone',
  [TimelineActivityType.ACTIVE_MILESTONE]: 'Milestone (Active)',
};

export const ALL_ACTIVITIES = 'All Activities';
export const ALL_MILESTONES = 'All Milestones';
export const NONE = 'NONE';
export const BUCKET = 'BUCKET';
export const BUCKET_MILESTONE = 'Milestone';
export const BUCKET_MEETING = 'Meeting';
export const UNGROUPED = 'UNGROUPED';
export const SORT_CATEGORIES_LEVEL = 'SORT_CATEGORIES_LEVEL';
export const SORT_COST_IMPACT = 'SORT_COST_IMPACT';
export const SORT_COST_IMPACT_MAGNITUDE = 'SORT_COST_IMPACT_MAGNITUDE';
export const SORT_CREATION_TIME = 'SORT_CREATION_TIME';
export const SORT_CREATOR = 'SORT_CREATOR';
export const SORT_DATE_MODIFIED = 'SORT_DATE_MODIFIED';
export const SORT_DUE_DATE = 'SORT_DUE_DATE';
export const SORT_ITEM_CATEGORIZATION = 'SORT_ITEM_CATEGORIZATION';
export const SORT_ITEM_NUMBERS = 'SORT_ITEM_NUMBERS';
export const SORT_NAME = 'SORT_NAME';
export const SORT_NUMBER = 'SORT_NUMBER';
export const SORT_STATUS = 'SORT_STATUS';
export const SORT_USER = 'SORT_USER';
export const Uncategorized = 'Uncategorized';
export const UNCATEGORIZED_ID = 'UNCATEGORIZED';
export const BULK_EDIT = 'BULK_EDIT';
export const PREVIEW_ROLE = 'PREVIEW_ROLE';

export const NONE_DUE_DATE = '01/01/1970 to 01/01/1970';

export const COSTS = 'costs';
export const CONTINGENCY_ALLOWANCE_REPORT = 'contingency-allowance-report';
export const PROJECT_COST_BREAKDOWN_REPORT = 'project-cost-breakdown-report';
export const DASHBOARD = 'dashboard';
export const COMPANY_DASHBOARD = 'Insights';
export const COMPANY_TAB = 'Admin';
export const COMPANY_INSIGHTS_ALERTS = 'Insights Alerts';
export const COMPANY_CATEGORIZATIONS = 'Categorizations';
export const COMPANY_PROJECT_STATS = 'Project Stats';
export const COMPANY_ORGANIZATIONS = 'Organizations';
export const COMPANY_PROJECT_TEMPLATES = 'Project Templates';
export const COMPANY_PROJECT_TYPES = 'Project Types';

export const FORECASTING_EXPLORE_PATH = 'explore';
export const FORECASTING_REPORTS_PATH = 'reports';
export const EXECUTIVE_DASHBOARD = 'execDashboard';
export const EXECUTIVE_DASHBOARD_PATH = 'insights';
export const PROJECTS = 'projects';
export const MILESTONES = 'milestones';
export const ITEMS = 'items';
export const REPORTS = 'reports';
export const ESTIMATE = 'estimate';
export const IMPORT = 'import';
export const TYPE = 'type';
export const DRAFT = 'DRAFT';
export const MANUAL = 'manual';
export const VARIANCE = 'variance';
export const METRICS = 'metrics';
export const SUBCOLUMNS = 'subcolumns';

export const REPORT_BAR_MIN_HEIGHT = 28;
export const ITEM_NUMBER_CHARLIMIT = 10;
export const ITEM_NAME_CHARLIMIT = 10;
export const DASHBOARD_TOOLTIP_LINE_CHARLIMIT = 70;
export const DASHBOARD_TOOLTIP_ITEM_CHARLIMIT = 20;
export const MAX_COL_WIDTH = 1276;

// TODO DD-843: Create role type to avoid need for special names
export const ADMINISTRATOR_ROLE = 'Administrator';

// PROJECTS PAGE
export const PROJECT_LOCATION = 'Location';
export const PROJECT_NAME = 'Name';
export const PROJECT_STATUS = 'Status';
export const PROJECT_COMPANIES = 'Company';
export const PROJECT_TYPE = 'Type';
export const SET_LOCATION_PLACEHOLDER = 'Set location';
export const SET_PROJECT_LOCATION_PLACEHOLDER = 'Set project location';
export const UNTITLED_PROJECT = 'Untitled Project';

export const FILTER_PROJECT_LOCATION = 'FILTER_PROJECT_LOCATION';
export const FILTER_PROJECT_STATUS = 'FILTER_PROJECT_STATUS';
export const FILTER_PROJECT_COMPANIES = 'FILTER_PROJECT_COMPANIES';
export const FILTER_PROJECT_TYPE = 'FILTER_PROJECT_TYPE';
export const FILTER_PROJECT_ORGANIZATION = 'FILTER_PROJECT_ORGANIZATION';
export const FILTER_PROJECT_LEAD = 'FILTER_PROJECT_LEAD';
export const FILTER_PROJECT_COSTS = 'FILTER_PROJECT_COSTS';
export const FILTER_PROJECT_SIZE = 'FILTER_PROJECT_SIZE';
export const FILTER_DESIGN_PHASE = 'FILTER_DESIGN_PHASE';
export const FILTER_DELIVERY_METHOD = 'FILTER_DELIVERY_METHOD';

export const SEARCH_PROJECT = 'SEARCH_PROJECT';
export const PROJECT_SEARCH_HEADER = 'Search';

export const LOCALSTORAGE_PROJECT_LIST_SHOW = 'ProjectsListShow';

export const GSF = 'GSF';

export const PROJECT_SHORTNAME = 'Project Shortname';
export const PROJECT_CURRENCY = 'Project Currency';

// PROJECT_STATUS
export const IN_PURSUIT_STATUS = 'In Pursuit';
export const ACTIVE_STATUS = 'Active';

// COMPANY TYPE
export const COMPANY_TYPE_ARCHITECTURE_DESIGN = 'Architecture / Design';
export const COMPANY_TYPE_BUILDING_PRODUCT_MANUFACTURER = 'Building Product Manufacturer';
export const COMPANY_TYPE_CONSTRUCTION_MANAGEMENT = 'Construction Management';
export const COMPANY_TYPE_DESIGN_ENGINEERING = 'Design Engineering';
export const COMPANY_TYPE_ENGINEERING_CONSTRUCTION = 'Engineering / Construction';
export const COMPANY_TYPE_GENERAL_CONTRACTOR = 'General Contractor';
export const COMPANY_TYPE_OWNER = 'Owner';
export const COMPANY_TYPE_SUBCONTRACTOR = 'Subcontractor';
export const COMPANY_TYPE_OTHER = 'Other';

// COMPANY TAB - MEMBERS/COLLABORATORS/PROJECT STATS
export const COMPANY_MEMBERS_NAME = 'Name';
export const COMPANY_MEMBERS_JOB_TITLE = 'Job Title';
export const COMPANY_MEMBERS_EMAIL = 'Email';
export const COMPANY_MEMBERS_STATUS = 'Status';
export const COMPANY_MEMBERS_ROLE = 'Company Role';
export const COMPANY_MEMBERS_LATEST_ACTIVITY = 'Latest Activity';

export const COMPANY_COLLABORATORS_NAME = 'Name';
export const COMPANY_COLLABORATORS_JOB_TITLE = 'Job Title';
export const COMPANY_COLLABORATORS_EMAIL = 'Email';
export const COMPANY_COLLABORATORS_COMPANY = 'Company';
export const COMPANY_COLLABORATORS_COMPANY_TYPE = 'Company Type';
export const COMPANY_COLLABORATORS_STATUS = 'Status';

export const COMPANY_PROJECT_STATS_PROJECT_NAME = 'Project Name';
export const COMPANY_PROJECT_STATS_TYPE = 'Type';
export const COMPANY_PROJECT_STATS_LOCATION = 'Location';
export const COMPANY_PROJECT_STATS_ORGANIZATION = 'Organization';
export const COMPANY_PROJECT_STATS_CREATED_ON = 'Created On';
export const COMPANY_PROJECT_STATS_STATUS = 'Status';
export const COMPANY_PROJECT_STATS_ESTIMATE = 'Estimate';
export const COMPANY_PROJECT_STATS_RUNNING_TOTAL = 'Running Total';
export const COMPANY_PROJECT_STATS_BUDGET = 'Budget';
export const COMPANY_PROJECT_STATS_CREATED_BY = 'Created By';
export const COMPANY_PROJECT_STATS_MILESTONES = 'Milestones';
export const COMPANY_PROJECT_STATS_ITEMS = 'Items';
export const COMPANY_PROJECT_STATS_TEAM = 'Team';
export const COMPANY_PROJECT_DESIGN_PHASE = 'Design Phase';
export const COMPANY_PROJECT_DELIVERY_METHOD = 'Delivery Method';
export const COMPANY_PROJECT_LEAD = 'Project Lead';

export const LOCALSTORAGE_COMPANY_USERS_SORT = 'CompanyUsersSort';
export const LOCALSTORAGE_COMPANY_MEMBER_PROJECTS_SORT = 'CompanyMemberProjectsSort';
export const LOCALSTORAGE_COMPANY_USERS_SHOW = 'CompanyUsersShow';

export const LOCALSTORAGE_COMPANY_COLLABORATORS_SORT = 'CompanyCollaboratorsSort';
export const LOCALSTORAGE_COMPANY_COLLABORATOR_PROJECTS_SORT = 'CompanyCollaboratorProjectsSort';
export const LOCALSTORAGE_COMPANY_COLLABORATORS_SHOW = 'CompanyCollaboratorsShow';

export const LOCALSTORAGE_COMPANY_PROJECT_STATS_SORT = 'CompanyProjectStatsSort';

export const LOCALSTORAGE_COMPANY_SEARCH_USER = 'CompanySearchUser';

// TEAM PAGE
export const TABLE_HEADER_EMAIL = 'Email';
export const TABLE_HEADER_NAME = 'Name';
export const TABLE_HEADER_RESPONSIBILITIES = 'Project Responsibilities';
export const TABLE_HEADER_ROLE = 'Role';
export const INVITED_COLLABORATOR = 'Invited';

// Asset types
export const MODEL = 'Model';
export const IMAGE = 'Image';
export const PDF = 'PDF';
export const REPORT_PDF = 'REPORT_PDF'; // used for analytics

export const ALL_COLLABORATORS = 'All roles';

export const DELETE_CELL = 'Delete';
export const STRING_CELL = 'String';
export const USCENTS_CELL = 'USCents';
export const MASTERFORMAT_CELL = 'MasterFormat';
export const MASTERFORMAT_CATEGORY = 'MasterFormat 2016';
export const UNIFORMAT_CELL = 'Uniformat';
export const UNIFORMAT_CATEGORY = 'UniFormat 2010';
export const INTRODUCTION = 'Introduction';
export const CATEGORY_CELL = 'Category';
export const INHERITED_MARKUP_CHECKBOX_CELL = 'InheritedMarkupCheckbox';
export const UNIFORMAT_DISPLAY = 'UniFormat';
export const NUMBER_CELL = 'Number';
export const DECIMAL_CELL = 'Decimal';
export const REFERENCE_CELL = 'Reference';
export const INHERITED_REFERENCE_CELL = 'InheritedReference';
export const SOURCE_CELL = 'Source';
export const ALLOCATED_CELL = 'Allocate';
export const SOURCE = 'Source';
export const TOTAL = 'Total';
export const MARKUP_APPLIED = 'APPLIED';
export const MARKUP_NOT_APPLIED = 'NOT_APPLIED';
export const MARKUP_PERCENT_SCALING_FACTOR = 1000;
export const MARKUP_FIXED_SCALING_FACTOR = 100;
export const MARKUP_CONVERSION_FACTOR = 10 ** (Scale.PERCENT_9 - Scale.PERCENT_5);
export const SELECT_CELL = 'Select';
export const MARKUP_APPLIES_TO_CELL = 'Applies to...';
export const MARKUP_TYPE_CELL = 'Type';
export const MARKUP_DISPLAY_TYPE = 'Display Type';
export const MARKUP_VALUE_CELL = 'Value';
export const ADD_COLUMN = 'addColumn';
export const DELETE_COLUMN = 'deleteColumn';
export const L = 'L';
export const M = 'M';
export const O = 'O';
export const P = 'P';
export const S1 = 'S1';
export const S2 = 'S2';
export const S3 = 'S3';
export const S4 = 'S4';
export const SUBTOTAL = 'Subtotal';
export const LUMP_SUM_DISPLAY = 'Lump sum';
export const PERCENT_DISPLAY = 'Percent';
export const MARKUP_APPLIED_DISPLAY = 'Yes';
export const MARKUP_NOT_APPLIED_DISPLAY = 'No';
export const MARKUP_TYPE_DISPLAY = 'Markup';
export const CONTINGENCY_TYPE_DISPLAY = 'Contingency';
export const CONTINGENCY_TYPE = 'contingency';
export const ALLOWANCE_TYPE_DISPLAY = 'Allowance';
export const ALLOWANCE_TYPE = 'allowance';
export const REPORT_TYPE = 'report-type';
export const DRAW_TYPE_DISPLAY = 'Draw';
export const DIRECT_COSTS = 'Direct Costs';
export const MARKUPS = 'Markups';
export const OWNER_COSTS = 'Owner Costs';
export const ITEM_MARKUPS = 'Item Markups';
export const MILESTONE_MARKUPS = 'Milestone Markups';
export const DRAW_MARKUPS = 'Contingency & Allowance Draws';
export const ADJUSTMENT_MARKUPS = 'Contingency & Allowance Adjustments';
export const CONTINGENCY_ALLOWANCE_REPORT_DISPLAY = 'Contingency & Allowance Report';
export const NUMBER = 'number';

export const CLEAR_REFERENCES = 'CLEAR_REFERENCES';
export const SELECT_ALL = 'SELECT_ALL';
export const RESOLVE_ERRORS = 'RESOLVE_ERRORS';
export const FILTER_PANEL = 'FILTER_PANEL';
export const RESET = 'reset';
export const REMOVE_FILTER = 'remove filter';

export const DESCRIPTION = 'Description';
export const U_M = 'U/M';
export const UNIT = 'Unit';
export const UNIT_PRICE = 'Unit Price';
export const QTY = 'QTY';
export const QUANTITY = 'Quantity';

export const COST = 'Cost';

// Pagination
export const PAGINATION_DEFAULT_LIMIT = 10;
export const PAGINATION_DEFAULT_PLUS_SIGN_AFTER = 100;

// ITEM HISTORY FILTER
export const ITEM_HISTORY = 'ITEM_HISTORY';
export const FILTER_COMMENTS = 'Comments';
export const FILTER_DRAFT_HISTORY = 'Draft item history';
export const ASSIGNEE_CHANGE = 'Assignee changes';
export const ATTACHMENT_CHANGE = 'Attachment changes';
export const CATEGORY_CHANGE = 'Category changes';
export const COST_CHANGE = 'Cost changes';
export const DUEDATE_CHANGE = 'Due date changes';
export const MEETING_CHANGE = 'Meeting changes';
export const EVENT_CHANGE = 'Event changes';
export const MILESTONE_CHANGE = 'Milestone changes';
export const NUMBER_NAME_DESCR_CHANGE = 'Number, name, and description changes';
export const OPTION_CHANGE = 'Option changes';
export const STATUS_CHANGE = 'Status changes';
export const SCHEDULE_IMPACT_CHANGE = 'Schedule impact changes';
export const TIMELINE_ACTIVITY_CHANGES = 'Timeline activity changes';
export const ITEM_DRAW_UPDATE_CHANGE = 'Item draw update';
export const ITEM_DRAW_SET_CHANGE = 'Item draw set';
export const ITEM_REFERENCE_CHANGE = 'Item reference changes';
export const PROCORE_CHANGE_EVENT = 'Procore Change Events';
export const AUTODESK_POTENTIAL_CHANGE_ORDERS = 'Autodesk Potential Change Orders';
export const SHARE_SETTING_CHANGE = 'Share setting changes';

// FILTER SELECT STATES
export const FILTER_ITEM_CREATOR_ALL = 'All creators';
export const FILTER_ITEM_CREATOR_NONE = 'No creators selected';
export const FILTER_ITEM_HISTORY_ALL = 'All comments and changes';
export const FILTER_ITEM_HISTORY_NONE = 'No changes selected';
export const FILTER_ITEM_STATUS_ALL = 'All statuses';
export const FILTER_ITEM_STATUS_NONE = 'No statuses selected';
export const FILTER_ITEM_SHARE_SETTINGS_ALL = 'All share settings';
export const FILTER_ITEM_SHARE_SETTINGS_NONE = 'No share settings selected';
export const FILTER_ITEM_INTEGRATIONS_ALL = 'All Items';

export const getFilterSelectEntries = (isScheduleImpactEnabled: boolean): string[] => {
  const entires = [
    ASSIGNEE_CHANGE,
    ATTACHMENT_CHANGE,
    CATEGORY_CHANGE,
    COST_CHANGE,
    FILTER_COMMENTS,
    DUEDATE_CHANGE,
    EVENT_CHANGE,
    FILTER_DRAFT_HISTORY,
    ITEM_DRAW_UPDATE_CHANGE,
    ITEM_DRAW_SET_CHANGE,
    ITEM_REFERENCE_CHANGE,
    MILESTONE_CHANGE,
    NUMBER_NAME_DESCR_CHANGE,
    OPTION_CHANGE,
    PROCORE_CHANGE_EVENT,
    AUTODESK_POTENTIAL_CHANGE_ORDERS,
    ...(isScheduleImpactEnabled ? [SCHEDULE_IMPACT_CHANGE] : []),
    SHARE_SETTING_CHANGE,
    STATUS_CHANGE,
    TIMELINE_ACTIVITY_CHANGES,
  ];

  // return entries in alphabetical order
  return entires.sort();
};

// ITEM HISTORY HELPERS
export const KIND_ITEM = 'item';
export const KIND_OPTION = 'option';
export const KIND_MILESTONE = 'milestone';
export const KIND_MEETING = 'meetingQuickLink';
/**
 * CopiedItem-specific event descriptions are a bit of one-off currently
 * in the past all item links in a project could rely on the projectID in the url for navigation
 * however, now we have an ability to show an item link that links out of the project
 * short term: I am defining a specific event description kind and kind-specific component to handle this case
 * long term: we should take a harder look at our various ItemLike, ItemLink, ItemSummary types to see how we can consolidate better
 */
export const KIND_COPIED_ITEM = 'copiedItemLike';
export const KIND_EVENT_DESCRIPTION_TEXT = 'eventDescriptionText';
export const KIND_EVENT_DESCRIPTION_NEW_LINE = 'eventDescriptionNewLine';
export const KIND_EVENT_DESCRIPTION_STATUS = 'eventDescriptionStatus';
export const KIND_EVENT_ITEM_LIKE_SUMMARY = 'itemLikeSummary';
export const KIND_EVENT_PRIVATE_ITEM_LIKE_SUMMARY = 'privateItemLikeSummary';
export const DELETED_MILESTONE = {
  name: 'Deleted Milestone',
  id: '',
};

// SEARCH KEY PARAMS
export const ASSIGNEE = 'assignee';
export const CATEGORIZATION_ID = 'categorizationId';
export const COLLAPSE = 'collapse';
export const CREATOR = 'creator';
export const CURRENT_MILESTONE = 'currentMilestone';
export const DUE_DATE = 'due';
export const EXPAND = 'expand';
export const FILTER_TEXT = 'filterText';
export const GROUPBY = 'groupBy';
export const IS_EXPANDED = 'isExpanded';
export const SEARCH = 'search';
export const SHOW = 'show';
export const SORT = 'sort';
export const STATUS = 'status';
export const VISIBILITY = 'visibility';
export const VIEW_FILTER = 'viewFilter';
export const FROM = 'from';
export const DUE_STATE = 'dueState';
export const ACTIVITY_ID = 'activityID';

export const MILESTONE = 'MILESTONE';
export const ACTIVE_ESTIMATE = 'ACTIVE_ESTIMATE';
export const BUDGET = 'BUDGET';
export const WITH_NAME = 'WITH_NAME';
export const WITHOUT_NAME = 'WITHOUT_NAME';
export const BREAKDOWNS = 'breakdowns';
export const OVERALL_PROJECT = 'Overall Project';

export const CHANGE_STATUS_EVENT = 'CHANGE_STATUS';
export const CHANGE_MILESTONE_EVENT = 'CHANGE_MILESTONE';
export const CHANGE_COST_EVENT = 'CHANGE_COST';
export const ITEM_DRAW_SET = 'ITEM_DRAW_SET';
export const CREATE_ITEM_EVENT = 'CREATE_ITEM';
export const REMOVE_MILESTONE_EVENT = 'REMOVE_FROM_MILESTONE';

export const TOTAL_REF = 'TOTAL';
export const COST_OF_CONSTRUCTION_REF = 'CC';

export const NEW_CATEGORIZATION = 'NEW_CATEGORIZATION';

// types of field errors
export const FIELD_ERROR_DRAFT_CATEGORIZATION = 'DRAFT_CATEGORIZATION';

// draft estimate constants
export const PREVIEW_MSR = 'Preview Milestone Report';
export const EDIT_ESTIMATE = 'Edit Estimate View';

// import estimate local storage
export const IMPORT_ESTIMATE_ACTIVE = 'IMPORT_ESTIMATE_ACTIVE';
export const IMPORT_ESTIMATE_BUDGET = 'IMPORT_ESTIMATE_BUDGET';

export const COST_TYPES = [
  CostType.LABOR_COST,
  CostType.EQUIPMENT_COST,
  CostType.MATERIAL_COST,
  CostType.SUBCONTRACTOR_COST,
  CostType.OTHER_COST,
  CostType.USER_COST,
];

export const COST_TYPES_DISPLAY = new Map<CostType, string>([
  [CostType.LABOR_COST, 'Labor'],
  [CostType.EQUIPMENT_COST, 'Equipment'],
  [CostType.MATERIAL_COST, 'Material'],
  [CostType.SUBCONTRACTOR_COST, 'Subcontractor'],
  [CostType.OTHER_COST, 'Other'],
  [CostType.USER_COST, 'User'],
]);

// track information to display help tips
export const HELP_TIP_TRACKER = 'HELP_TIP_TRACKER';

// track report distribution experiment
export const REPORT_DISTRIBUTION_EXPERIMENT_TRACKER = 'REPORT_DISTRIBUTION_EXPERIMENT_TRACKER';

// variance
export const VARIANCE_REACTIVE_VAR = 'VARIANCE_REACTIVE_VAR';
export const VARIANCE_KEY = 'VARIANCE_KEY';
export const ZERO_VARIANCE = 'Zero Variance';
export const DETAILED_ITEM_COSTS = 'Detailed Item Costs';
export const VARIANCE_HEADER = 'Variance';

// categorized metrics
export const CATEGORIZED = 'Categorized';
export const METRICS_TITLE = 'Metrics';

// milestone estimate + item merge

// transition
export const TRANSITION_REACTIVE_VAR = 'TRANSITION_REACTIVE_VAR';

// project comps reactive var
export const PROJECT_COMPS_REACTIVE_VAR = 'PROJECT_COMPS_REACTIVE_VAR';

// in app notifications polling intervals
export const IN_APP_NOTIFICATIONS_POLLING_INTERVAL_LONG = 1000 * 60; // [ms]
export const IN_APP_NOTIFICATIONS_POLLING_INTERVAL_SHORT = 1000 * 15; // [ms]
export const PROJECT_ACTIVITY_PAGINATION_LIMIT = 100;
export const IN_APP_NOTIFICATIONS_PAGINATION_PLUS_SIGN_AFTER = 200;

// cost reports
export const DISPLAY = 'Display';
export const HIDE = 'Hide';
export const NO_DESCRIPTION = '(No description)';

// ANALYTICS
// These are used in reporting analytics about categorization editing (location)
export const CATEGORIZATION_LIST = 'CATEGORIZATION_LIST';

export const MASTERFORMAT_CATEGORIZATION_ID = 'c22ed8a1-8fb3-43fc-b9ad-1ecf514c9852';
export const UNIFORMAT_CATEGORIZATION_ID = 'f7716c5d-5970-40b9-b215-03cc1b9773bf';
export const NULL_ID = '00000000-0000-0000-0000-000000000000';
export const GSF_ID = '17c46793-7493-4df3-9d41-4cedd0dfd05c';
export const GSM_ID = 'fd53c244-0550-4ef0-98cb-72a996f37294';

export const DIVISION = 'Division';
export const SUBDIVISION = 'Subdivision';
export const SECTION = 'Section';
export const SUBSECTION = 'Subsection';

export const TRENDLINE_EVENT_DEFAULT = 1.5;
export const TRENDLINE_EVENT_ESTIMATE = 5;

export const ITEMTYPE_ITEM = 'ITEM';
export const ITEMTYPE_ITEM_WITH_OPTIONS = 'ITEM_WITH_OPTIONS';
export const ITEMTYPE_OPTION = 'OPTION';
export const VISIBILITY_PUBLISHED_ITEM = 'Published';
export const VISIBILITY_PRIVATE_ITEM = 'Private';
export const VISIBILITY_DRAFT_ITEM = 'Draft';
export const VISIBILITY_PUBLISH_ITEM = 'Publish to all';
export const VISIBILITY_INTERNAL_DRAFT = 'Internal Draft'; // NOTE: strings out of date with shift to public/private, update once they are in use
export const VISIBILITY_SHARED_DRAFT = 'Shared Draft'; // NOTE: strings out of date with shift to public/private, update once they are in use

const VIEW_MODE_CHART = 'chart';
const VIEW_MODE_CHART_AND_LIST = 'chart-list';
export const VIEW_MODE_LIST = 'list';
export const VIEW_OPTIONS = {
  CHART_AND_LIST: VIEW_MODE_CHART_AND_LIST,
  CHART: VIEW_MODE_CHART,
  LIST: VIEW_MODE_LIST,
};

export const TOAST_VAR = 'TOAST_VAR';

export const PROJECT_ITEM_TEMPLATE = 'template';

// EXECUTIVE DASHBOARD
export const ACTIVE_ITEMS_SUMMARY = 'ITEMS SUMMARY';
export const APV_BY_LOCATION = 'PROJECT VALUE BY LOCATION';
export const BUDGET_GAP_BY_PROJECT = 'BUDGET GAP BY PROJECT';
export const CURRENT_ITEMS_BREAKDOWN_BY_COST_IMPACT = 'CURRENT ITEMS BREAKDOWN BY COST IMPACT';
export const ITEMS_BY_ASSIGNEE = 'ITEMS BY ASSIGNEE';
export const LARGEST_RECENT_MOVEMENT = 'LARGEST MOVEMENT - LAST 30 DAYS';
export const MOST_RECENTLY_CREATED = 'NEWEST PROJECTS';
export const OPEN_ITEMS_DUE = 'OPEN ITEMS DUE';
export const PROJECTS_OVERVIEW = 'PROJECTS OVERVIEW';
export const PROJECT_ACTIVE_USERS = 'ACTIVE USERS BY PROJECT';
export const TOP_USERS = 'TOP USERS - LAST 30 DAYS';
export const OTHER = 'Other';
export const OTHER_PROJECT_TYPES = 'Other Project Types';

// Pagination limit
export const LIMIT = 10;

// ITEM SIDEBAR
export const GENERAL_INFO = 'General Info';
export const COST_IMPACT = 'Cost Impact';
export const ESTIMATE_SUMMARY = 'Estimate';
export const OPTIONS = 'Options';
export const CATEGORIZATIONS = 'Categorizations';
export const ATTACHMENTS = 'Attachments';
export const COMMENTS_AND_EDIT_HISTORY = 'Comments and Edit History';
export const ESTIMATE_CONTENT = 'ESTIMATE_CONTENT';
export const CONTINGENCY = 'Contingency';
export const SCHEDULE_IMPACT = 'Schedule Impact';
export const SIDEBAR = 'SIDEBAR';
export const INTEGRATIONS = 'Integrations';

export const GROUP_BY = 'Group By';

// WINDOW EVENTS
export const RESIZE = 'resize';

// KEYPRESS
export const ENTER = 'Enter';
export const ESC = 'Escape';
export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_RIGHT = 'ArrowRight';
export const ARROW_UP = 'ArrowUp';
export const ARROW_DOWN = 'ArrowDown';

// PROJECT COMPS
export const CATEGORIZATION_NAME_ALREADY_EXISTS_ERROR =
  'Categorization name already exists. Try a different one.';
export const CATEGORIZATION_NAME_NOT_VALID_ERROR =
  'Categorization name is not valid. Try a different one.';
export const CONFIRM_DELETE = 'Yes, delete';
export const CUSTOMIZE_BUTTON = 'Customize';
export const DELETE_AVERAGE_COLUMN = 'Delete average column';
export const DELETE_BUTTON = 'Delete';
export const EDIT_COMP_DESCRIPTION = 'Edit description';
export const HIDE_BUTTON = 'Hide';
export const LOST_DATA_MESSAGE =
  'All the edited data in this column will be lost once you delete the average column.';
export const NEW_COLUMN_FROM_AVERAGES = 'New Column from Averages';
export const PROJECT_COMPS_LOCAL_SETTINGS_VAR = 'PROJECT_COMPS_LOCAL_SETTINGS_VAR';
export const RESET_AVERAGE_COMP_COST = 'Reset to average';
export const RESET_COMP_COST = 'Reset project cost';
export const RESET_COMP_NAME = 'Reset name';
export const SELECT_DIFFERENT_FILE = 'Select a different file...';
export const SELECT_FILE = 'Select a file...';
export const SHOW_BUTTON = 'Show';

export const AUTH_REDIRECT = 'AUTH_REDIRECT';
export const AUTHORIZE = 'authorize';

export const SIGN_UP = 'signup';

export const UPLOADING_IMAGE_PLACEHOLDER = '/img/loading.png';

// chip types
export const CATEGORIZATION_REORDER = 'CATEGORIZATION_REORDER';

export const UNTITLED_SCENARIO = 'Untitled Scenario';

// Project Activity Feed
export const ITEM_ACTIVITY_FEED_REPORT_TYPE = 'ITEM_ACTIVITY_FEED_REPORT_TYPE';
export const PROJECT_ACTIVITY_EVENT_TABLE_SCROLL_ID = 'activity-event-table-scroll-container';

export const DEFAULT_MARGIN = {
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
};
