import { createContext, useContext } from 'react';

const FeatureFlagContext = createContext<string[]>([]);
FeatureFlagContext.displayName = 'FeatureFlagContext';

export const FeatureFlagContextProvider = FeatureFlagContext.Provider;

export function useFeatureFlags() {
  return useContext(FeatureFlagContext);
}
