import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-unresolved
import { NuqsAdapter } from 'nuqs/adapters/react-router/v6';

import routes from '../routes';

const router = createBrowserRouter(routes);

function App() {
  return (
    <NuqsAdapter>
      <RouterProvider router={router} />
    </NuqsAdapter>
  );
}

export default Sentry.withProfiler(App);
