import { isUUID } from 'validator';

import { isPrivateVisibility } from '../components/Items/ItemsUtils';
import {
  ACCEPTED,
  ASSIGNEE,
  BUCKET,
  DUE_DATE,
  INCORPORATED,
  ITEM_CATEGORIES,
  MILESTONE,
  PENDING,
  REJECTED,
  STATE_LIST_HOVER_SELECTOR_ARRAY,
  STATUS,
  UNGROUPED,
} from '../constants';
import { GetSharedResourcesQuery, ItemType, Maybe, Status, Visibility } from '../generated/graphql';

import { isCostScalar } from './compare';
import { capitalizeString, pluralizePosession, pluralizeString, pluralizeWord } from './string';
import { isNonNullable } from './types';

type MilestoneState = {
  costImpact?: Cost;
  optionSubtotals?: OptionSubtotal[];
  status: Status;
};

export type ItemState = MilestoneState & {
  categorizedState?: MilestoneState;
  filteredMilestoneState?: MilestoneState;
  previousMilestoneStatus?: MilestoneState[];
  id: UUID;
  categories?: Category[];
  hasOptions?: boolean;
  parent?: UUID;
};

// getFilteredItemState- returns other states that have already been filtered or categorized in
// items list setup functions: compute...Contributions
export const getFilteredItemState: (itemLike: ItemState) => MilestoneState = (itemLike) => {
  const state: MilestoneState =
    itemLike.categorizedState || itemLike.filteredMilestoneState || itemLike;
  // HACK TO RETURN THE RIGHT STATUS FOR NOT-CHOSEN PENDING OPTIONS
  if (
    itemLike.parent &&
    itemLike.status === PENDING &&
    itemLike.categorizedState &&
    itemLike.categorizedState.status === REJECTED
  ) {
    return { ...state, status: Status.PENDING };
  }
  return state;
};

// TODO: Remove this and all references to this, ItemLike has costMode aware cost now
export const getItemCost = (
  item: Partial<Pick<ItemLike, 'cost' | 'previousMilestoneStatus'>> | undefined | null,
  milestoneID?: string
) => {
  if (!item) {
    const cost: CostScalar = { value: '0' };
    return cost;
  }
  if (milestoneID) {
    const itemMilestoneCost = item.previousMilestoneStatus?.find(
      (s) => s.milestoneID === milestoneID
    )?.cost;
    return itemMilestoneCost ?? undefined;
  }
  return item.cost ?? undefined;
};

export const getContingencyDrawCosts = (
  item: Pick<ItemLike, 'contingencyDrawCost' | 'previousMilestoneStatus'> | undefined | null,
  milestoneID?: string
): Cost => {
  if (!item) {
    return { value: 0 };
  }
  if (milestoneID) {
    const itemMilestoneCost = item.previousMilestoneStatus?.find(
      (s) => s.milestoneID === milestoneID
    )?.contingencyDrawCost;
    return itemMilestoneCost ?? undefined;
  }
  return item.contingencyDrawCost ?? { value: 0 };
};

export const getTotalDrawCost = (itemContingencies?: ItemContingencies): number => {
  const drawValues = itemContingencies?.draws?.map((d) => Number(d.draw));
  if (!drawValues?.length) return 0;
  return drawValues.reduce((draw, total) => draw + total) ?? 0;
};

export const getNegativeEstimateValue = (contingencyCost: Cost) =>
  -getEstimateValue(contingencyCost);

export const getEstimateValue = (contingencyCost: Cost) =>
  contingencyCost && 'value' in contingencyCost ? Number(contingencyCost.value) : 0;

export const getEstimateCosts = (
  item: Pick<ItemLike, 'estimateCost' | 'previousMilestoneStatus'> | undefined | null,
  milestoneID?: string
): Cost => {
  if (!item) {
    return { value: 0 };
  }
  if (milestoneID) {
    const itemMilestoneCost = item.previousMilestoneStatus?.find(
      (s) => s.milestoneID === milestoneID
    )?.estimateCost;
    return itemMilestoneCost ?? undefined;
  }
  return item.estimateCost ?? { value: 0 };
};

export const getCostOfConstructionPlusOwnerCosts = (
  costImpact: Cost | CostScalar | undefined,
  contingencyDrawTotal: Cost,
  costMode: CostMode
): CostScalar => {
  const costImpactNumber = costImpact && isCostScalar(costImpact) ? Number(costImpact.value) : 0;
  if (!costMode.includeOwnerCosts) return { value: costImpactNumber };
  return {
    value:
      costImpactNumber +
      (isCostScalar(contingencyDrawTotal) ? -Number(contingencyDrawTotal.value) : 0),
  };
};

// TODO: Remove this and all references to this, ItemLike has costMode aware unfilteredCost now
export const getUnfilteredCostImpactByCostMode = (
  item: Pick<ItemLike, 'cost' | 'unfilteredCost'>
) => item.unfilteredCost || item.cost;

export const nonZeroCost = (cost: Cost) =>
  cost &&
  (((cost as CostRange).min && (cost as CostRange).min !== '0') ||
    ((cost as CostRange).max && (cost as CostRange).max !== '0') ||
    ((cost as CostScalar).value && (cost as CostScalar).value !== '0'));

// Returns an aggregate costImpact range or value for a list of costImpacts
// This is used in the item's list footer
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export function generateVisibleItemsCostImpactRange(visibleItemsCostImpacts: any[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const costImpactValueArray: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const costImpactRangMinArray: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
  const costImpactRangMaxArray: any[] = [];
  visibleItemsCostImpacts.forEach((costImpact: Cost) => {
    if (costImpact) {
      const cost = costImpact as CostScalar;
      if (cost.value) {
        costImpactValueArray.push(cost.value);
      } else {
        const costRange = costImpact as CostRange;
        costImpactRangMinArray.push(costRange.min);
        costImpactRangMaxArray.push(costRange.max);
      }
    }
  });
  let result;
  const baseCostSum =
    costImpactValueArray.length > 0
      ? costImpactValueArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      : 0;
  if (costImpactRangMaxArray.length > 0) {
    const minSum = costImpactRangMinArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    const maxSum = costImpactRangMaxArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    result = {
      min: (baseCostSum + minSum).toString(),
      max: (baseCostSum + maxSum).toString(),
    };
  } else {
    result = { value: baseCostSum.toString() };
  }
  return result;
}

export const computeDistinctCreators = (items: ItemsListItem[]) => {
  const creators: UserLink[] = [];
  items.forEach(({ creator }) => creators.push(creator));
  return creators.reduce((unique: UserLink[], o: UserLink) => {
    if (!unique.some((obj) => obj.id === o.id)) unique.push(o);
    return unique.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name));
  }, []);
};

export const getAssigneeIDs = (items: ItemsListItem[]) =>
  (items ?? []).map(({ assigneeID }) => assigneeID).filter(isNonNullable);

export const createItemsAssigneeMap = (
  items: ItemsListItem[],
  users: (UserLink | undefined | null)[] | undefined
) => {
  const map = new Map<string, UserLink | undefined | null>();
  (items ?? []).forEach(({ id, assigneeID }) => {
    const user = (users ?? []).find((user) => user?.id === assigneeID);
    map.set(id, user);
  });
  return map;
};

export function computeItemCostImpact<T extends object>(
  item: T
): T & { costImpact: ReturnType<typeof getItemCost> } {
  return {
    ...item,
    costImpact: getItemCost(item),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO CT-567: Fix this pls :)
export const computeCollapseState = (settings: any) =>
  settings.expand.length === 0 && settings.collapse[0] === UNGROUPED;

// ITEMS LIST

export const calculateSelectedItemsAndOptionsListsTree = (
  itemsList: ItemsListItem[],
  itemsLikeSelected: UUID[]
) => itemsList.filter(({ id }) => itemsLikeSelected.some((i) => i === id));

export const getSelectedDraftItems = (itemsList: ItemsListItem[], itemsLikeSelected: UUID[]) =>
  calculateSelectedItemsAndOptionsListsTree(itemsList, itemsLikeSelected).filter(({ visibility }) =>
    isPrivateVisibility(visibility)
  );

const generatePuluralizedItemText = (selectedLength: number) => {
  const thisWord = pluralizeWord('this', selectedLength);
  const itemWord = pluralizePosession('item', selectedLength);
  const isWord = pluralizeWord('is', selectedLength);
  return { thisWord, itemWord, isWord };
};

// Takes number of selected items and generates the beginning of the tooltip string
// which we see when user is not allowed to edit field.
const generateNoPermissionsString = (selectedLength: number) => {
  const { thisWord, itemWord } = generatePuluralizedItemText(selectedLength);
  return `You do not have permission to edit ${thisWord} ${itemWord}`;
};

export const getDisabledBulkEditPanelLabel = (variant: string, selectedItemsLikeLength: number) => {
  const noPermissionString = generateNoPermissionsString(selectedItemsLikeLength);
  switch (variant) {
    case STATUS:
      return `${noPermissionString}status.`;
    case MILESTONE:
      return `${noPermissionString}milestone.`;
    case BUCKET:
      return `${noPermissionString}meetings.`;
    case DUE_DATE:
      return `${noPermissionString}due date.`;
    case ASSIGNEE:
      return `${noPermissionString}assignee.`;
    case ITEM_CATEGORIES:
      return 'You do not have permission to choose categories outside of your trade.';
    default:
      return `You do not have permission to edit this field.`;
  }
};

const getPrivateBulkEditItemStatusLabel = (selectedItemsLike: unknown[]) => {
  if (selectedItemsLike.length === 1) {
    return `This item is private. Make this item public to update the status.`;
  }
  const { thisWord, itemWord, isWord } = generatePuluralizedItemText(selectedItemsLike.length);
  return `Some of ${thisWord} ${itemWord} ${isWord} private. Make ${thisWord} ${itemWord} public to update the status.`;
};

const getIWOAndOptionsListTree = (itemsList: ItemsListItem[]) => {
  const itemLikeList: ItemsListItem[] = [];
  itemsList.forEach((item: ItemsListItem) => {
    if (item.parent) {
      itemLikeList.push(item);
      const parentItem = itemsList.find(({ id }) => id === item.parent?.id);
      if (parentItem) itemLikeList.push(parentItem);
    }
  });
  return itemLikeList;
};

const isOptionInSelection = (selectedItemIDs: string[], option: Option): boolean =>
  selectedItemIDs.some((selected: string) => option.id === selected);

const getIwoOptionsNotInSelection = (
  selectedItemLike: ItemLike | undefined,
  selectedItemIDs: string[]
): ItemLike[] => {
  const returnValues: ItemLike[] = [];
  (selectedItemLike as Item).options.forEach((option: Option) => {
    if (!isOptionInSelection(selectedItemIDs, option)) {
      returnValues.push(option);
    }
  });
  return returnValues;
};

const calculateOptionTransitionValidTree = (
  itemsList: ItemsListItem[],
  selectedItemIDs: string[],
  selectedItemLike?: ItemLike | ItemsListItem
): ItemsListItem[] => {
  const returnValues = [];
  // TODO: unwind all these helper functions that use both ItemLike and ItemsListItem
  const parent = itemsList.find(({ id }) => {
    if (
      selectedItemLike &&
      'parent' in selectedItemLike &&
      typeof selectedItemLike.parent === 'object'
    ) {
      return id === selectedItemLike.parent?.id;
    }

    return false;
  });
  const selectedParent = selectedItemIDs.find((id: string) => parent && parent.id === id);
  if (!selectedParent && parent) {
    returnValues.push(parent);
  }
  itemsList.forEach((option: ItemsListItem) => {
    if ((option.parent || {}).id === (parent || {}).id && parent) {
      if (!isOptionInSelection(selectedItemIDs, option as unknown as Option)) {
        returnValues.push(option);
      }
    }
  });
  return returnValues;
};

const isOption = (itemLike: ItemLike): itemLike is Option =>
  'parent' in itemLike && !!itemLike.parent && itemLike.itemType === ItemType.OPTION;
const isItem = (itemLike: ItemLike): itemLike is Item =>
  itemLike.itemType === ItemType.ITEM_WITH_OPTIONS || itemLike.itemType === ItemType.ITEM;

// Function takes item like array and returns an object of options and items(with options and without options)
const calculateItemsAndOptions = (
  itemsLike: ItemLike[],
  input?: ItemsListBulkEditingInput
): { options: Option[]; items: Item[] } => {
  let options: Option[] = itemsLike.filter(isOption);
  const items = itemsLike.filter(isItem);
  // if we're changing the milestone for an item
  // then we also automatically move it's children
  // so add those children to the list of options
  // if they aren't already there
  if (input && input.milestoneID) {
    itemsLike.forEach((itemLike) => {
      if (itemLike && itemLike.itemType === ItemType.ITEM_WITH_OPTIONS && 'options' in itemLike) {
        const itemOptions = itemLike.options.filter(
          (option) => options.findIndex((o) => o.id === option.id) < 0
        );
        options = options.concat(...itemOptions);
      }
    });
  }

  return { options, items };
};

const getItemLikeWithoutNullVisibility = (itemLike: ItemsListItem | ItemLink) => ({
  ...itemLike,
  visibility: itemLike.visibility ?? undefined,
});

export const calculateItemsListItemsAndOptions = (itemsLike: (ItemsListItem | ItemLink)[]) => {
  const options = itemsLike
    .filter(
      (itemLike) => 'itemType' in itemLike && itemLike.itemType === ItemType.ITEM_WITH_OPTIONS
    )
    .map(getItemLikeWithoutNullVisibility);
  const items = itemsLike
    .filter(
      (itemLike) =>
        'itemType' in itemLike &&
        (itemLike.itemType === ItemType.ITEM_WITH_OPTIONS || itemLike.itemType === ItemType.ITEM)
    )
    .map(getItemLikeWithoutNullVisibility);

  return { options, items };
};

const calculateItemOptionMilestoneTransition = (
  selectedItemIDs: string[],
  itemsList: ItemsListItem[]
) => {
  // FIXME: The casts to ItemLike in this function are lying, esp the cast of the return value
  //        from `getIWOAndOptionsListTree`. That function returns options which have
  //        differences such as the type of the `parent` property which affects downstream calls.
  const missingItemsLike: ItemLike[] = [] as ItemLike[];
  const itemLikeList: ItemLike[] = getIWOAndOptionsListTree(itemsList) as unknown as ItemLike[];
  if (selectedItemIDs) {
    selectedItemIDs.forEach((itemID: string) => {
      const selectedItemLike = itemLikeList.find((item: ItemLike) => itemID === item.id);
      const itemType = selectedItemLike && selectedItemLike.itemType;
      if (itemType && itemType === ItemType.ITEM_WITH_OPTIONS) {
        const item = selectedItemLike as Item;
        const options = getIwoOptionsNotInSelection(item, selectedItemIDs);
        // if no options or all of the options are selected then it's fine to transition the item
        // if only some of the items are selected then ask the user to add select all the items
        if (item && item.options.length !== options.length) {
          missingItemsLike.push(...options);
        }
      }
      if (itemType && itemType === ItemType.OPTION && isOption(selectedItemLike)) {
        const optionStuff = calculateOptionTransitionValidTree(
          itemsList,
          selectedItemIDs,
          selectedItemLike
        ) as unknown as ItemLike[];
        missingItemsLike.push(...optionStuff);
      }
    });
  }
  return Array.from(new Set<ItemLike>(missingItemsLike));
};

const getMissingOptionNumbers = (itemsLike: ItemLike[]) => {
  const optionNumArray: string[] = [];
  itemsLike.forEach((i: ItemLike) => {
    if (i && i.itemType && i.itemType === ItemType.OPTION) {
      optionNumArray.push(i.number);
    }
  });
  return optionNumArray.join(', ');
};

export const calculateMilestoneUpdateProps = (
  canEditItemsMilestone: boolean,
  itemsLikeSelected: string[],
  itemsList: ItemsListItem[]
): MilestoneUpdateProps => {
  if (!canEditItemsMilestone) {
    return {
      isEditable: canEditItemsMilestone,
      defaultLabel: getDisabledBulkEditPanelLabel(MILESTONE, itemsLikeSelected.length),
      missingItemsLike: [],
    } as MilestoneUpdateProps;
  }

  const missingItemsLike: ItemLike[] = calculateItemOptionMilestoneTransition(
    itemsLikeSelected,
    itemsList
  );
  const missingItems = calculateItemsAndOptions(missingItemsLike);
  const { options, items } = missingItems;
  const iwo = items.filter((item) => item.itemType === ItemType.ITEM_WITH_OPTIONS);
  if (missingItemsLike.length > 0) {
    const optionWord: string = pluralizeString('option', options.length);
    const parentWord: string = pluralizeString('parent', iwo.length);
    const itemWord: string = pluralizeString('item', iwo.length);
    const isIWO: boolean =
      iwo.length > 0 && options.length !== 0 && !(iwo.length > 0 && options.length === 0);
    const itemsString = `${isIWO ? 'and select ' : 'Select '}${optionWord} ${parentWord}`;
    return {
      isEditable: false,
      defaultLabel: `${capitalizeString(
        optionWord
      )} must be in the same milestone as parent ${itemWord} and siblings.\n${
        options.length > 0 ? `Select ${optionWord} ${getMissingOptionNumbers(options)}` : ''
      } ${iwo.length !== 0 ? itemsString : ''} to edit milestone.`,
      missingItemsLike,
    } as MilestoneUpdateProps;
  }
  return {
    isEditable: true,
    defaultLabel: 'Choose Milestone',
    missingItemsLike,
  } as MilestoneUpdateProps;
};

const calculateSharedAvailableStatuses = (
  selectedIWOandOptionList: (Partial<Pick<ItemLike, 'availableStates' | 'itemType'>> & {
    parent?: UUID | null;
  })[]
) => {
  const allAvailableStatuses: string[][] = selectedIWOandOptionList.map(
    (itemLike) => itemLike.availableStates || STATE_LIST_HOVER_SELECTOR_ARRAY
  );

  // Get list of shared available states for all selected IWO and options
  let sharedAvailableStatuses: string[] = [];
  if (allAvailableStatuses.length > 0)
    sharedAvailableStatuses = allAvailableStatuses.reduce((a: string[], b: string[]) =>
      a.filter((status) => b.includes(status))
    );
  // Check if user selected two sibling options, if true remove accepting statuses;
  const optionsParents = selectedIWOandOptionList
    .filter((itemLike) => 'itemType' in itemLike && itemLike.itemType === ItemType.OPTION)
    .map((option) => option.parent);
  const areSiblingOptionsSelected = new Set(optionsParents).size !== optionsParents.length;
  if (areSiblingOptionsSelected) {
    return sharedAvailableStatuses.filter(
      (status: string) => status !== ACCEPTED && status !== INCORPORATED
    );
  }
  return sharedAvailableStatuses;
};

const getItemsOptionsLabelStrings = (itemsAndOptions: {
  options: unknown[];
  items: Partial<Pick<Item, 'itemType'>>[];
}) => {
  const { options = [], items } = itemsAndOptions;
  const optionsString = options.length > 0 ? pluralizeString('option', options.length) : '';
  const iwo = items.filter((item) => item.itemType === ItemType.ITEM_WITH_OPTIONS);
  const itemsLikeString = items.length > 0 ? pluralizeString('item', items.length) : '';
  const iwoString = iwo.length > 0 ? `${pluralizeString('item', iwo.length)} with options ` : '';
  const andString = options.length > 0 && iwo.length > 0 ? 'and ' : '';
  const orString = options.length > 0 && iwo.length > 0 ? 'or ' : '';

  return { itemsLikeString, optionsString, iwoString, andString, orString };
};

const getPrivateItemsSelected = (itemsAndOptions: {
  options: Partial<Pick<Item, 'visibility'>>[];
  items: Partial<Pick<Item, 'visibility'>>[];
}) => {
  const { options, items } = itemsAndOptions;
  const privateItemsLikeSelected = [...options, ...items].find((item) =>
    isPrivateVisibility(item.visibility)
  );
  return !!privateItemsLikeSelected;
};

const calculateDefaultStatusLabel = (
  availableStates: string[],
  itemsAndOptions: Parameters<typeof getItemsOptionsLabelStrings>[0]
) => {
  const { itemsLikeString, optionsString, iwoString, andString, orString } =
    getItemsOptionsLabelStrings(itemsAndOptions);
  if (availableStates.length === 0) {
    return `Selected ${optionsString} ${andString}${itemsLikeString} do not have shared available statuses. \nUpdate ${optionsString} ${andString}${iwoString}status before updating \nmultiple items.`;
  }
  return `Selected ${optionsString} ${andString}${itemsLikeString} have limited selection of available statuses. \nDeselect ${optionsString} ${orString}${iwoString}if you want to select from all statuses.`;
};

export const calculateStatusUpdateProps = (
  canEditItemsStatus: boolean,
  selectedItemLikeList: Parameters<typeof calculateSharedAvailableStatuses>[0],
  itemsAndOptions: {
    options: Partial<Pick<Option, 'visibility'>>[];
    items: Partial<Pick<Item, 'visibility' | 'itemType'>>[];
  }
): StatusUpdateProps => {
  const privateItemsLikeSelected = getPrivateItemsSelected(itemsAndOptions);
  if (!canEditItemsStatus || privateItemsLikeSelected) {
    return {
      availableStates: [],
      defaultLabel: 'Selection is disabled',
      isEditable: canEditItemsStatus,
      tooltipText: privateItemsLikeSelected
        ? getPrivateBulkEditItemStatusLabel(selectedItemLikeList)
        : getDisabledBulkEditPanelLabel(STATUS, selectedItemLikeList.length),
    };
  }
  const availableStates: string[] = calculateSharedAvailableStatuses(selectedItemLikeList);
  const tooltipText: string = calculateDefaultStatusLabel(availableStates, itemsAndOptions);
  const defaultLabel = 'Choose Status';

  if (availableStates.length === 0) {
    return {
      availableStates,
      defaultLabel: 'Selection is disabled',
      isEditable: false,
      tooltipText,
    };
  }
  if (availableStates.length < 5) {
    return {
      availableStates,
      defaultLabel,
      isEditable: true,
      tooltipText,
    };
  }
  return {
    availableStates,
    defaultLabel,
    isEditable: canEditItemsStatus,
    tooltipText: defaultLabel,
  };
};

export const calculateDisableApplyChangesButton = (input: ItemsListBulkEditingInput) => {
  const {
    addCategories,
    assigneeEmail,
    clearAssigneeEmail,
    clearCategories,
    clearDueDate,
    clearMeetingID,
    dueDate,
    meetingID,
    milestoneID,
    status,
  } = input;
  return (
    addCategories.length === 0 &&
    !!clearCategories &&
    clearCategories.length === 0 &&
    assigneeEmail.length === 0 &&
    !dueDate &&
    !isUUID(milestoneID) &&
    !isUUID(meetingID) &&
    !clearMeetingID &&
    !clearAssigneeEmail &&
    !clearDueDate &&
    !status
  );
};

export const computePayload = (payload?: Maybe<string>): BookmarkPayload | undefined => {
  if (!payload) return undefined;
  try {
    const parsed = JSON.parse(payload) as BookmarkPayload;
    return parsed;
  } catch (e) {
    return payload as unknown as BookmarkPayload;
  }
};

export type ItemPreviousMilestone = ItemLike['previousMilestoneStatus'][0] & {
  milestone?: Milestone;
};

export const getItemMilestoneMappings = (
  item: Pick<ItemLike, 'previousMilestoneStatus'>,
  milestones: Milestone[]
): ItemPreviousMilestone[] =>
  item &&
  item.previousMilestoneStatus &&
  item.previousMilestoneStatus
    .filter((s) => s.milestoneID)
    .map((s) => ({ ...s, milestone: (milestones || []).find((m) => m?.id === s.milestoneID) }))
    .filter((s) => s.milestone);

export const isDraft = ({ visibility }: Pick<ItemLike, 'visibility'>) =>
  visibility === Visibility.SHARED_DRAFT || visibility === Visibility.PRIVATE_DRAFT;

export const getDraftItemIDs = (list: Pick<ItemLike, 'id' | 'visibility'>[]) =>
  list.filter(isDraft).map((it) => it.id);

export const getSharedUsersMap = (
  list: Pick<ItemLike, 'id' | 'visibility'>[],
  sharedResources: GetSharedResourcesQuery | undefined
) => {
  const resources = sharedResources?.getSharedResources?.resources ?? [];
  return new Map(
    list.flatMap((it) => {
      if (isDraft(it)) {
        return [[it.id, resources.find((r) => r.id === it.id)?.users ?? []]];
      }
      return [];
    })
  );
};
