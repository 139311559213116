import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import RouteReport from '../components/ReportsTab/RouteReport';

import paths from './paths';

/**
 * https://reactrouter.com/en/main/upgrading/v6-data#add-routerprovider-with-a-root-splat-route
 *
 * We're upgrading the router onto RR 6.4 incrementally. All new routes should
 * ideally be added here instead of the routes.tsx file that's in this directory
 * unless there's a good reason for it not to be the case. See comments
 * throughout this file to see how to add routes incrementally.
 *
 * To do so:
 *   1. `paths.ts`: Add a new entry to the `RouteKeys` enum.
 *   2. `paths.ts`: Add the path into `paths`. Note that paths are relative and
 *      do not include the full path.
 *   3. `index.tsx`: Add a route into the `routes` array. Note that:
 *      - You can define index routes if desired:
 *          https://reactrouter.com/en/main/start/concepts#index-routes
 *
 */

// RouteObject: https://reactrouter.com/en/main/route/route
const routes: RouteObject[] = [
  {
    /**
     * We define all our top-level routes that rely on auth'd users here.
     *
     * In this transition period between routers, we don't need to fully
     * define all routes in this array because we use the splat route
     * at the end to render the <Router /> as a fallback method of routing.
     *
     * As we bring in more routes, the children will get more filled-out and
     * eventually we'll be able to render <Navigate to="/404" /> instead of
     * <Router /> as the fallback.
     */
    path: paths.ROOT,
    lazy: async () => ({
      Component: (await import('../components/RootRoute')).default,
    }),
    children: [
      /** These routes do not render the Nav. */
      {
        path: paths.APOLLO,
        lazy: async () => ({
          Component: (await import('../components/ApolloSandbox')).default,
        }),
      },
      {
        path: paths.AUTHORIZE,
        lazy: async () => ({
          Component: (
            await import('../components/DocumentMagic/AuthorizeRedirectPage/AuthorizeRedirectPage')
          ).default,
        }),
      },
      {
        path: paths.DEACTIVATED,
        lazy: async () => ({
          Component: (await import('../components/DeactivatedRoute')).default,
        }),
      },
      {
        path: paths.NOT_FOUND,
        lazy: async () => ({
          Component: (await import('../components/FourOhFour')).default,
        }),
      },

      {
        path: paths.EXPORT,
        lazy: async () => ({ Component: (await import('../components/Print/PrintRoute')).default }),
        children: [
          {
            path: paths.EXPORT_PROJECT,
            lazy: async () => ({
              Component: (await import('../components/ExportRoute/ExportRoute')).default,
            }),
            children: [
              {
                path: paths.EXPORT_PROJECT_ACTIVE_MILESTONE_CONTINGENCY,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/Print/ExportContingency/ExportActiveMilestoneContingency'
                    )
                  ).default,
                }),
              },
              {
                path: paths.EXPORT_PROJECT_ALL_MILESTONES_CONTINGENCY,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/Print/ExportContingency/ExportAllMilestonesContingency'
                    )
                  ).default,
                }),
              },
              {
                path: paths.EXPORT_PROJECT_ITEMS_LIST,
                lazy: async () => ({
                  Component: (await import('../components/Print/ExportItemsList/ExportItemsList'))
                    .default,
                }),
              },
              {
                path: paths.EXPORT_PROJECT_MILESTONE_BUDGET,
                lazy: async () => ({
                  Component: (await import('../components/Print/ExportMilestone/ExportMilestone'))
                    .default,
                }),
              },
              {
                path: paths.EXPORT_PROJECT_MILESTONE_ESTIMATE,
                lazy: async () => ({
                  Component: (await import('../components/Print/ExportMilestone/ExportMilestone'))
                    .default,
                }),
              },
              {
                path: paths.EXPORT_PROJECT_MSR,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/Print/ExportMilestoneSummaryReport/ExportMilestoneSummaryReport'
                    )
                  ).default,
                }),
              },
              {
                path: paths.EXPORT_PROJECT_COST_BREAKDOWN_REPORT,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/ProjectCostBreakdownReport/ExportProjectCostBreakdownReportData'
                    )
                  ).default,
                }),
              },
              {
                path: paths.EXPORT_PROJECT_VARIANCE,
                lazy: async () => ({
                  Component: (await import('../components/Print/ExportVariance/ExportVariance'))
                    .default,
                }),
              },
            ],
          },
        ],
      },
      {
        path: paths.PRINT,
        lazy: async () => ({ Component: (await import('../components/Print/PrintRoute')).default }),
        children: [
          {
            path: paths.PRINT_PROJECT,
            lazy: async () => ({
              Component: (await import('../components/ProjectRoute/ProjectRoute')).default,
            }),
            children: [
              {
                path: paths.PRINT_PROJECT_BREAKDOWNS,
                lazy: async () => ({
                  Component: (
                    await import('../components/Print/PrintBreakdowns/PrintBreakdownsData')
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/Print/PrintContingencyReport/PrintContingencyReport'
                    )
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_CONTINGENCY_ALLOWANCE_REPORT,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/Print/PrintContingencyReport/PrintContingencyReport'
                    )
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_COST_BREAKDOWN,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/Print/PrintProjectCostBreakdown/PrintProjectCostBreakdown'
                    )
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_DASHBOARD_SUMMARY,
                lazy: async () => ({
                  Component: (await import('../components/Print/PrintDashboard/PrintDashboardData'))
                    .default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_DASHBOARD,
                lazy: async () => ({
                  Component: (await import('../components/Print/PrintDashboard/PrintDashboardData'))
                    .default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_ITEM_DETAILS,
                lazy: async () => ({
                  Component: (
                    await import('../components/Print/PrintItemDetails/PrintViewItemDetails')
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_ITEMS_LIST,
                lazy: async () => ({
                  Component: (
                    await import('../components/Print/PrintItemsListNew/PrintViewItemsList')
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_ITEMS_LIST_DETAILS,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/Print/PrintItemsListNew/PrintViewItemsListAndItemDetailsList'
                    )
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_MSR_SUMMARY,
                lazy: async () => ({
                  Component: (await import('../components/Print/PrintCostReport/PrintCostReport'))
                    .default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_MSR,
                lazy: async () => ({
                  Component: (await import('../components/Print/PrintCostReport/PrintCostReport'))
                    .default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_REPORT,
                element: <RouteReport isPrint />,
              },
              {
                path: paths.PRINT_PROJECT_SCENARIOS,
                lazy: async () => ({
                  Component: (
                    await import('../components/Print/PrintScenariosSandbox/PrintScenariosSandbox')
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_VARIANCE,
                lazy: async () => ({
                  Component: (
                    await import('../components/Print/PrintVarianceReport/PrintVarianceReport')
                  ).default,
                }),
              },
              {
                path: paths.PRINT_PROJECT_RISKS,
                lazy: async () => ({
                  Component: (await import('../components/Print/RisksRoute/RisksRoute')).default,
                }),
                children: [
                  {
                    path: paths.PRINT_PROJECT_RISKS_LIST,
                    lazy: async () => ({
                      Component: (
                        await import('../components/Print/RisksRoute/RisksListRoute/RisksListRoute')
                      ).default,
                    }),
                  },
                  {
                    path: paths.PRINT_PROJECT_RISKS_LIST_AND_DETAILS,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/Print/RisksRoute/RisksListAndDetailsRoute/RisksListAndDetailsRoute'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.PRINT_PROJECT_RISK_DETAILS,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/Print/RisksRoute/RiskDetailsRoute/RiskDetailsRoute'
                        )
                      ).default,
                    }),
                  },
                ],
              },
            ],
          },
        ],
      },

      /** Most of our routes should render the Nav sidebar but some do not. We
       * use a Layout Route [1] to provide this functionality for those
       * routes.
       *
       * [1](https://reactrouter.com/en/main/start/concepts#layout-routes)
       */
      {
        lazy: async () => ({
          Component: (await import('../components/Nav/Nav')).default,
        }),
        children: [
          {
            path: paths.BENCHMARKING,
            lazy: async () => ({
              Component: (await import('../components/ForecastingRoute/ForecastingRoute')).default,
            }),
            children: [
              {
                path: paths.BENCHMARKING_EXPLORE,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/ForecastingRoute/ForecastingExplore/ForecastingExplore'
                    )
                  ).default,
                }),
              },
              {
                path: paths.BENCHMARKING_REPORTS,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/ForecastingRoute/ForecastingReports/ForecastingReports'
                    )
                  ).default,
                }),
              },
              {
                path: paths.BENCHMARKING_PROJECT_COMPS,
                lazy: async () => ({
                  Component: (await import('../components/ProjectComps/ProjectCompsSetData'))
                    .default,
                }),
              },
              {
                path: paths.BENCHMARKING_PROJECT_COMPS_SAVED,
                lazy: async () => ({
                  Component: (await import('../components/ProjectComps/ProjectCompsSetData'))
                    .default,
                }),
              },
              {
                lazy: async () => ({
                  Component: (await import('../components/Print/PrintRoute')).default,
                }),
                children: [
                  {
                    path: paths.BENCHMARKING_PROJECT_COMPS_PRINT,
                    lazy: async () => ({
                      Component: (
                        await import('../components/Print/PrintProjectComps/PrintProjectComps')
                      ).default,
                    }),
                  },
                  {
                    path: paths.BENCHMARKING_PROJECT_COMPS_SAVED_PRINT,
                    lazy: async () => ({
                      Component: (
                        await import('../components/Print/PrintProjectComps/PrintProjectComps')
                      ).default,
                    }),
                  },
                ],
              },
            ],
          },
          {
            path: paths.COMPANY,
            lazy: async () => ({
              Component: (await import('../components/CompanyTab/CompanyRoute')).default,
            }),
            children: [
              {
                path: paths.COMPANY_EMPLOYEES,
                element: <Outlet />, // kind of lazy, we wouldn't do this if writing from scratch
                children: [
                  {
                    index: true,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/CompanyTab/CompanyTabMembers/CompanyTabMembersData'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.COMPANY_EMPLOYEES_USER,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/CompanyTab/CompanyTabMembersProfile/CompanyTabMembersProfileData'
                        )
                      ).default,
                    }),
                  },
                ],
              },
              {
                path: paths.COMPANY_INSIGHTS_ALERTS,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/CompanyTab/CompanyTabInsightsAlerts/CompanyTabInsightsAlertsData'
                    )
                  ).default,
                }),
              },
              {
                path: paths.COMPANY_ORGANIZATIONS,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/CompanyTab/CompanyTabOrganizations/CompanyTabOrganizationsData'
                    )
                  ).default,
                }),
                children: [
                  {
                    path: paths.COMPANY_ORGANIZATIONS_ORGANIZATION,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/CompanyTab/CompanyTabOrganizations/CompanyOrganizationData'
                        )
                      ).default,
                    }),
                  },
                ],
              },

              {
                path: paths.COMPANY_PARTNERS,
                element: <Outlet />, // kind of lazy, we wouldn't do this if writing from scratch
                children: [
                  {
                    index: true,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/CompanyTab/CompanyTabCollaborators/CompanyTabCollaboratorsData'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.COMPANY_PARTNERS_USER,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/CompanyTab/CompanyTabCollaboratorsProfile/CompanyTabCollaboratorsProfileData'
                        )
                      ).default,
                    }),
                  },
                ],
              },
              {
                path: paths.COMPANY_PROJECT_STATS,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/CompanyTab/CompanyTabProjectStats/CompanyTabProjectStatsData'
                    )
                  ).default,
                }),
              },
              {
                path: paths.COMPANY_SETTINGS,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/CompanyTab/CompanyTabSettings.tsx/CompanyTabSettingsData'
                    )
                  ).default,
                }),
              },
              {
                path: paths.COMPANY_STANDARDS,
                lazy: async () => ({
                  Component: (await import('../components/CompanyTab/Standards/StandardsData'))
                    .default,
                }),
                children: [
                  {
                    path: paths.COMPANY_STANDARDS_CATEGORIZATIONS,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/CompanyTab/Standards/CompanyCategorizations/CompanyCategorizationsData'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.COMPANY_STANDARDS_PROJECT_TEMPLATES,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/CompanyTab/Standards/ProjectTemplates/ProjectTemplatesData'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.COMPANY_STANDARDS_PROJECT_TYPES,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/CompanyTab/Standards/ProjectTypes/ProjectTypesData'
                        )
                      ).default,
                    }),
                  },
                ],
              },
            ],
          },
          {
            path: paths.INSIGHTS,
            lazy: async () => ({
              Component: (await import('../components/ExecutiveDashboard/ExecutiveDashboardRoute'))
                .default,
            }),
            children: [
              {
                path: paths.INSIGHTS_PROJECT,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/ExecutiveDashboardV2/InsightsProject/InsightsProjectRoute'
                    )
                  ).default,
                }),
              },
            ],
          },
          {
            path: paths.INSIGHTS_CLASSIC,
            lazy: async () => ({
              Component: (await import('../components/ExecutiveDashboard/InsightsClassicRoute'))
                .default,
            }),
            children: [],
          },
          {
            path: paths.PROGRAM,
            lazy: async () => ({
              Component: (await import('../components/ProgramReporting/ProgramReportingRoute'))
                .default,
            }),
            children: [
              {
                path: paths.PROGRAM_DASHBOARD_PRINT,
                lazy: async () => ({
                  Component: (
                    await import('../components/ProgramReporting/PrintProgramDashboardRoute')
                  ).default,
                }),
              },
              {
                path: paths.PROGRAM_DASHBOARD,

                lazy: async () => ({
                  Component: (await import('../components/ProgramReporting/ProgramDashboardRoute'))
                    .default,
                }),
              },
            ],
          },
          {
            path: paths.PROJECT,
            lazy: async () => ({
              Component: (await import('../components/ProjectRoute/ProjectRoute')).default,
            }),
            children: [
              {
                path: paths.PROJECT_CONTINGENCY_ALL_MILESTONES_REPORT,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/ContingencyReport/ContingencyReportData/ContingencyReportData'
                    )
                  ).default,
                }),
              },
              {
                path: paths.PROJECT_CONTINGENCY_ALLOWANCE_REPORT,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/ContingencyReport/ContingencyReportData/ContingencyReportData'
                    )
                  ).default,
                }),
              },
              {
                path: paths.PROJECT_DASHBOARD,
                lazy: async () => ({
                  Component: (await import('../components/dashboard/DashboardRoute'))
                    .DashboardRoute,
                }),
              },
              {
                path: paths.PROJECT_EXPORT,
                lazy: async () => ({
                  Component: (await import('../components/PageHasMoved')).default,
                }),
              },
              {
                path: paths.PROJECT_IDEAS,
                lazy: async () => ({
                  Component: (await import('../components/IdeasRoute/IdeasRoute')).default,
                }),
                children: [
                  {
                    index: true,
                    lazy: async () => ({
                      Component: (
                        await import('../components/IdeasRoute/IndexRoute/IdeasIndexRoute')
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_IDEAS_IDEA,
                    lazy: async () => ({
                      Component: (await import('../components/IdeasRoute/IdeaRoute/IdeaRoute'))
                        .default,
                    }),
                  },
                ],
              },
              {
                path: paths.PROJECT_ITEMS,
                lazy: async () => ({
                  Component: (await import('../components/ProjectItemsRoute/ProjectItemsRoute'))
                    .default,
                }),
                children: [
                  {
                    index: true,
                    lazy: async () => ({
                      Component: (await import('../components/ItemsList/ItemsListData')).default,
                    }),
                  },

                  {
                    path: paths.PROJECT_ITEMS_ACTIVITY,
                    lazy: async () => ({
                      Component: (
                        await import('../components/Items/ItemActivityFeed/ItemActivityFeedData')
                      ).default,
                    }),
                  },

                  {
                    path: paths.PROJECT_ITEMS_ITEM,
                    lazy: async () => ({
                      Component: (await import('../components/ProjectItemsRoute/ProjectItemRoute'))
                        .default,
                    }),
                    children: [
                      {
                        index: true,
                        lazy: async () => ({
                          Component: (await import('../components/ve-item-details/ItemDetailsData'))
                            .default,
                        }),
                      },
                      {
                        path: paths.PROJECT_ITEMS_ITEM_ESTIMATE,
                        lazy: async () => ({
                          Component: (
                            await import(
                              '../components/estimate/EstimateAccordion/EstimateAccordionItem'
                            )
                          ).default,
                        }),
                      },
                    ],
                  },
                ],
              },
              {
                path: paths.PROJECT_MILESTONES,
                lazy: async () => ({
                  Component: (await import('../components/MilestonesRoute/MilestonesRoute'))
                    .default,
                }),
                children: [
                  {
                    lazy: async () => ({
                      Component: (await import('../components/Milestones/Milestones')).default,
                    }),
                    index: true,
                  },
                  {
                    path: paths.PROJECT_MILESTONES_MILESTONE,
                    lazy: async () => ({
                      Component: (await import('../components/Milestone/MilestoneRoute')).default,
                    }),
                    children: [
                      {
                        path: paths.PROJECT_MILESTONES_MILESTONE_COST_BREAKDOWN_REPORT,
                        lazy: async () => ({
                          Component: (
                            await import(
                              '../components/ProjectCostBreakdownReport/ProjectCostBreakdownReportData'
                            )
                          ).default,
                        }),
                      },
                      {
                        path: paths.PROJECT_MILESTONES_MILESTONE_IMPORT_ESTIMATE,
                        lazy: async () => ({
                          Component: (
                            await import(
                              '../components/ImportEstimate/ImportEstimateData/ImportEstimateData'
                            )
                          ).default,
                        }),
                      },
                      {
                        path: paths.PROJECT_MILESTONES_MILESTONE_MSR,
                        lazy: async () => ({
                          Component: (
                            await import('../components/CostReport/CostReportData/CostReportRoute')
                          ).default,
                        }),
                      },
                      {
                        path: paths.PROJECT_MILESTONES_MILESTONE_TRENDING_COST_REPORT,
                        lazy: async () => ({
                          Component: (
                            await import(
                              '../components/TrendingCostReport/TrendingCostReportData/TrendingCostReportData'
                            )
                          ).default,
                        }),
                      },
                    ],
                  },
                ],
              },
              {
                path: paths.PROJECT_REPORT_BACKCOMPAT,
                lazy: async () => ({
                  Component: (await import('../components/ReportsTab/RouteReport'))
                    .RouteReportBackCompat,
                }),
              },
              {
                path: paths.PROJECT_REPORT_DISTRIBUTION_FILE,
                lazy: async () => ({
                  Component: (
                    await import('../components/ReportDistributionView/ReportDistributionView')
                  ).default,
                }),
              },
              {
                path: paths.PROJECT_REPORT_DISTRIBUTION_UNSUBSCRIBE,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/ReportDistributionUnsubscribe/ReportDistributionUnsubscribe'
                    )
                  ).default,
                }),
              },

              {
                path: paths.PROJECT_REPORTS,
                element: <Outlet />, // kind of lazy, we wouldn't do this if writing from scratch
                children: [
                  {
                    index: true,
                    lazy: async () => ({
                      Component: (await import('../components/Reports/ReportsRoute')).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_REPORT,
                    lazy: async () => ({
                      Component: (await import('../components/ReportsTab/RouteReport')).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_MSR,
                    lazy: async () => ({
                      Component: (
                        await import('../components/CostReport/CostReportData/CostReportRoute')
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_VARIANCE,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/VarianceReport/VarianceReportData/VarianceReportData'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_ALL_MILESTONE_CONTINGENCY,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/ContingencyReport/ContingencyReportData/ContingencyReportData'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_ACTIVE_MILESTONE_CONTINGENCY,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/ContingencyReport/ContingencyReportData/ContingencyReportData'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_PCBR,
                    lazy: async () => ({
                      Component: (
                        await import(
                          '../components/ProjectCostBreakdownReport/ProjectCostBreakdownReportData'
                        )
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_BREAKDOWNS,
                    lazy: async () => ({
                      Component: (
                        await import('../components/Print/PrintBreakdowns/PrintBreakdownsData')
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_DASHBOARD,
                    lazy: async () => ({
                      Component: (await import('../components/dashboard/DashboardRoute'))
                        .DashboardRoute,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_ITEMS,
                    lazy: async () => ({
                      Component: (await import('../components/ProjectItemsRoute/ProjectItemsRoute'))
                        .default,
                    }),
                  },
                  {
                    path: paths.PROJECT_REPORTS_RISK,
                    lazy: async () => ({
                      Component: (await import('../components/RisksRoute/RisksRoute')).default,
                    }),
                  },
                ],
              },
              {
                path: paths.PROJECT_PRINT,
                lazy: async () => ({
                  Component: (await import('../components/PageHasMoved')).default,
                }),
              },
              {
                path: paths.PROJECT_SCENARIOS,
                lazy: async () => ({
                  Component: (await import('../components/Scenarios/ScenariosRoute'))
                    .ScenariosRoute,
                }),
              },
              {
                path: paths.PROJECT_SETTINGS,
                lazy: async () => ({
                  Component: (await import('../components/ProjectProperties/ProjectPropertiesData'))
                    .default,
                }),
              },
              {
                path: paths.PROJECT_SIGNUP,
                element: <Outlet />, // kind of lazy, we wouldn't do this if writing from scratch

                children: [
                  {
                    path: paths.PROJECT_SIGNUP_CONFIRM_COMPANY,
                    lazy: async () => ({
                      Component: (
                        await import('../components/login/Signup/SignupPages/ConfirmCompanyData')
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_SIGNUP_CREATE_COMPANY,
                    lazy: async () => ({
                      Component: (
                        await import('../components/login/Signup/SignupPages/CreateCompanyData')
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_SIGNUP_SETUP_USER,
                    lazy: async () => ({
                      Component: (
                        await import('../components/login/Signup/SignupPages/SetupUserData')
                      ).default,
                    }),
                  },
                ],
              },
              {
                path: paths.PROJECT_TEAM,
                lazy: async () => ({
                  Component: (await import('../components/TeamRoute/TeamRoute')).default,
                }),
                children: [
                  {
                    path: paths.PROJECT_TEAM_TEAMMATES,
                    lazy: async () => ({
                      Component: (await import('../components/TeamRoute/TeammatesRoute')).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_TEAM_COMPANIES,
                    lazy: async () => ({
                      Component: (await import('../components/TeamRoute/CompaniesRoute')).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_TEAM_ROLES,
                    lazy: async () => ({
                      Component: (await import('../components/TeamRoute/RolesRoute/RolesRoute'))
                        .default,
                    }),
                    children: [
                      {
                        path: paths.PROJECT_TEAM_ROLES_ROLE_VIEW,
                        lazy: async () => ({
                          Component: (await import('../components/TeamRoute/RolesRoute/RoleRoute'))
                            .default,
                        }),
                      },
                      {
                        path: paths.PROJECT_TEAM_ROLES_ROLE_EDIT,
                        lazy: async () => ({
                          Component: (
                            await import('../components/TeamRoute/RolesRoute/RoleEditRoute')
                          ).default,
                        }),
                      },
                    ],
                  },
                ],
              },
              {
                path: paths.PROJECT_TIMELINE,
                lazy: async () => ({
                  Component: (await import('../components/TimelineRoute/TimelineRoute')).default,
                }),
                children: [
                  {
                    index: true,
                    lazy: async () => ({
                      Component: (await import('../components/TimelineRoute/Timeline/TimelineV2'))
                        .default,
                    }),
                  },
                ],
              },
              {
                path: paths.PROJECT_VARIANCE,
                lazy: async () => ({
                  Component: (
                    await import(
                      '../components/VarianceReport/VarianceReportData/VarianceReportData'
                    )
                  ).default,
                }),
              },
              {
                path: paths.PROJECT_RISKS,
                lazy: async () => ({
                  Component: (await import('../components/RisksRoute/RisksRoute')).default,
                }),
                children: [
                  {
                    index: true,
                    lazy: async () => ({
                      Component: (
                        await import('../components/RisksRoute/IndexRoute/RisksIndexRoute')
                      ).default,
                    }),
                  },
                  {
                    path: paths.PROJECT_RISK_DETAILS,
                    lazy: async () => ({
                      Component: (await import('../components/RisksRoute/RiskRoute/RiskRoute'))
                        .default,
                    }),
                  },
                ],
              },
            ],
          },
          {
            path: paths.SEARCH_ITEMS,
            lazy: async () => ({
              Component: (await import('../components/HomeTab/Search/Search')).default,
            }),
          },
          {
            path: paths.SEARCH_PROJECTS,
            lazy: async () => ({
              Component: (await import('../components/HomeTab/Search/Search')).default,
            }),
          },
          {
            path: paths.SPLAT,
            element: <Navigate replace to="/404" />,
          },
        ],
      },
    ],
  },
];

export default routes;
