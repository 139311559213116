import { RefObject, useEffect, useState } from 'react';

/**
 * We can have multiple active themes on a page currently. This is to support
 * the fact that the nav sidebar is designed against a dark theme while the rest
 * the app is still a regular light theme. As a result, we need to check our
 * ancestors until we find which theme we're currently in when inspecting an element.
 */
export default function useActiveTheme(ref: RefObject<Element | null>) {
  const [theme, setTheme] = useState<string>();

  // Generally, rely on a useEffect so we don't look too often.
  useEffect(() => {
    const foundTheme = findActiveTheme(ref.current);
    if (foundTheme) {
      setTheme(foundTheme);
    }
  }, [ref]);

  // But always look if there's no theme to prevent a flash-of-white where we default to light.
  if (!theme) {
    const foundTheme = findActiveTheme(ref.current);
    if (foundTheme) {
      setTheme(foundTheme);
    }
  }

  return theme;
}

function findActiveTheme(startingElement: Element | null) {
  let currentElement = startingElement;
  while (currentElement) {
    const value = currentElement.getAttribute?.('data-theme');
    if (value) {
      return value;
    }

    currentElement = currentElement.parentElement;
  }

  return undefined;
}
