import { FC, ReactNode, createContext } from 'react';
import { titleCase } from 'title-case';

import { TermKey } from '../../api/gqlEnums';
import { pluralizeString } from '../../utilities/string';

import { TerminologyDefaults } from './constants';
import { useProjectSettings, useProjectTerminology } from './hooks';

const capitalize = (str: string) => {
  if (str.length === 0) return str;
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
};
const lower = (str: string) => str.toLowerCase();
const makeLookup = (terms: Terminology[], transform: (str: string) => string) => {
  const termDict = new Map<string, string>();
  terms.forEach((term: Terminology) =>
    termDict.set(
      term.key,
      term.override && term.overrideValue
        ? term.overrideValue
        : TerminologyDefaults[term.key as TermKey]
    )
  );
  return (term: TermKey): string => {
    const value = termDict.get(term);
    return value ? transform(value) : '';
  };
};

const pluralizeSentenceCase = (str: string) => {
  return capitalize(pluralizeString(str, 2));
};

export const defaultStore = {
  rawTerm: (term: TermKey) => (term ? TerminologyDefaults[term] : ''),
  lowerCase: (term: TermKey) => (term ? lower(TerminologyDefaults[term]) : ''),
  titleCase: (term: TermKey) => (term ? titleCase(TerminologyDefaults[term]) : ''),
  sentenceCase: (term: TermKey) => (term ? capitalize(TerminologyDefaults[term]) : ''),
  pluralSentenceCase: (term: TermKey) =>
    term ? pluralizeSentenceCase(TerminologyDefaults[term]) : '',
};

export const ProjectTerminologyContext = createContext<TermStore>(defaultStore);
ProjectTerminologyContext.displayName = 'ProjectTerminologyContext';

type ProjectTermProviderProps = {
  children: ReactNode;
  projectID?: UUID;
};

export const ProjectTermProvider: FC<ProjectTermProviderProps> = ({ projectID, children }) => {
  // Ensure that the global project settings are loaded appropriately.
  useProjectSettings(projectID);

  const terms = useProjectTerminology(projectID);
  const value = terms
    ? {
        rawTerm: makeLookup(terms, (str: string) => str),
        lowerCase: makeLookup(terms, lower),
        titleCase: makeLookup(terms, titleCase),
        sentenceCase: makeLookup(terms, capitalize),
        pluralSentenceCase: makeLookup(terms, pluralizeSentenceCase),
      }
    : defaultStore;
  return (
    <ProjectTerminologyContext.Provider value={value}>
      {children}
    </ProjectTerminologyContext.Provider>
  );
};
