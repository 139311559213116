import { useQuery as useApolloQuery } from '@apollo/client';

import {
  IntegrationFilterType,
  ItemsListQuery,
  ItemsListQueryVariables,
  VisibilityView,
} from '../../generated/graphql';
import { useQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import { useCostMode } from '../contexts/cost-mode';

import { itemsListQuery } from './hooks/itemsListQuery';

export const useItemsListQuery = (
  milestoneID: UUID | null,
  activityID: UUID | null,
  projectID: UUID,
  showOptions: boolean,
  viewFilter: ViewFilterInput,
  visibilityView: VisibilityView,
  integrations: IntegrationFilterType[],
  loadItemCosts: boolean,
  skipQuery?: boolean
) =>
  useQuery<ItemsListQuery, ItemsListQueryVariables>(
    itemsListQuery,
    {
      variables: {
        milestoneID,
        activityID,
        projectID,
        integrations: integrations ?? [], // if the integrations prop doesn't exist in local storage, because these settings were created before it was introduced then we need to provide a default value.
        showOptions,
        costMode: useCostMode(),
        viewFilter,
        visibilityView,
        loadItemCosts,
      },
      skip: skipQuery || !projectID,
    },
    MountPolicy.FETCH_ON_VARS
  );

/** @deprecated useCachedItemsListQuery
 * is used to produce navigation for
 * the items list in the item details nav. It is used to get the
 * data from the cache only.
 *
 * It will need to be kept up to date with the useItemsListQuery
 * param inputs
 */
export const useCachedItemsListQuery = (
  milestoneID: UUID | null,
  activityID: UUID | null,
  projectID: UUID,
  showOptions: boolean,
  viewFilter: ViewFilterInput,
  visibilityView: VisibilityView,
  integrations: IntegrationFilterType[],
  loadItemCosts: boolean,
  skipQuery: boolean
) =>
  useApolloQuery<ItemsListQuery, ItemsListQueryVariables>(itemsListQuery, {
    variables: {
      milestoneID,
      activityID,
      projectID,
      integrations,
      showOptions,
      costMode: useCostMode(),
      viewFilter,
      visibilityView,
      loadItemCosts,
    },
    fetchPolicy: 'cache-only',
    skip: skipQuery || !projectID,
  });
