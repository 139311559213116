import { createContext, useContext } from 'react';

import { AnalyticsPropertiesQuery } from '../../generated/graphql';

const AnalyticsContext = createContext<AnalyticsPropertiesQuery['analyticsProperties'] | undefined>(
  undefined
);

AnalyticsContext.displayName = 'AnalyticsContext';

export const AnalyticsContextProvider = AnalyticsContext.Provider;

export function useAnalyticsProperties() {
  const analyticsProperties = useContext(AnalyticsContext);
  return analyticsProperties;
}
