import { ReactNode, RefObject, useRef } from 'react';
import { OverlayContainer, mergeProps, useOverlayPosition, useTooltip } from 'react-aria';
import { TooltipTriggerState } from 'react-stately';

import useActiveTheme from '../Popover/useActiveTheme';

type Props = {
  children: ReactNode;
  /** @default 8 */
  offset?: Parameters<typeof useOverlayPosition>[0]['offset'];
  /** @default 'top' */
  placement?: Parameters<typeof useOverlayPosition>[0]['placement'];
  state: TooltipTriggerState;
  /** Adds transparent padding around the tooltip. Combined with an `offset` of
   * 0, this allows a user to mouse into the tooltip while still appearing as
   * though there is a normal offset. The lack of offset will enable mouse
   * movement into the tooltip, while the transparent padding will make it look
   * like there is an offset. @default 0 */
  transparentPadding?: number;
  triggerRef: RefObject<HTMLDivElement | SVGElement | null>;
};

export default function TooltipOverlay(_props: Props) {
  const props = { ..._props };
  props.offset ??= 8;
  props.placement ??= 'top';
  props.transparentPadding ??= 0;

  const overlayRef = useRef(null);
  const { tooltipProps } = useTooltip({}, props.state);
  const { overlayProps } = useOverlayPosition({
    offset: props.offset,
    overlayRef,
    placement: props.placement,
    targetRef: props.triggerRef,
  });

  const activeTheme = useActiveTheme(props.triggerRef);

  if (props.transparentPadding) {
    overlayProps.style = { ...overlayProps.style, padding: props.transparentPadding };
  }

  return (
    <OverlayContainer data-theme={activeTheme}>
      <div ref={overlayRef} className="bg-transparent" {...mergeProps(tooltipProps, overlayProps)}>
        <div className="max-w-[400px] overflow-hidden rounded-md border border-border-muted bg-background-1 p-2 text-type-primary shadow">
          {props.children}
        </div>
      </div>
    </OverlayContainer>
  );
}
