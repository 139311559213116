/*
  These are sets of queries that need to be refetched on a given action. The queries must be named in graphql-queries.js
  and can then be passed directly into the refetchQueries field of useMutation. Apollo will only run queries that are currently
  being used by rendered components and
  will use the correct parameters from those components
*/

import { InternalRefetchQueryDescriptor } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';

import { itemBookmarksQuery } from '../components/assets/AssetsCards/ModelCardWithBookmarks/queries';
import {
  assetsQuery,
  itemAttachmentsQuery,
  milestoneAttachmentsQuery,
  projectAssetsQuery,
} from '../components/assets/hooks/queries';
import {
  loadCollaboratorsMultipleProjects,
  loadCollaboratorsQuery,
} from '../components/Collaborators/hooks/queries';
import {
  companyProjectRolesQuery,
  getAlertsProjectStatusSettingQuery,
  getAlertsQuery,
  getCompanyProjectStatsQuery,
} from '../components/CompanyTab/queries';
import { companyCategorizationsQuery } from '../components/CompanyTab/Standards/CompanyCategorizations/queries';
import { projectTemplatesQuery } from '../components/CompanyTab/Standards/ProjectTemplates/queries';
import { minimalCostReportsQuery } from '../components/CostReport/hooks/queries';
import { costTrendlineEventsQuery } from '../components/dashboard/DashboardCharts/DashboardChartsTrend/hooks/queries';
import { userSourcesQuery } from '../components/DocumentMagic/hooks/queries';
import { itemHistoryQuery } from '../components/Events/EventsItemHistoryList/hooks/queries';
import {
  hasUnreadInAppNotificationsQuery,
  loadInAppNotificationsCountQuery,
  loadInAppNotificationsQuery,
} from '../components/InAppNotifications/hooks/queries';
import { itemQuery } from '../components/Items/hooks/itemQuery';
import {
  itemCostQuery,
  itemCurrentCostQuery,
  itemsListQuery,
  itemsListTotalCostQuery,
  itemsTreeQuery,
} from '../components/ItemsList/hooks/itemsListQuery';
import { getSharedResourcesQuery } from '../components/ItemsList/ItemsSharing/hooks/queries';
import {
  getMilestoneItemCountQuery,
  getMilestoneQuantitiesQuery,
  getMilestoneQuery,
  getProjectUnitsQuery,
} from '../components/Milestone/hooks/queries';
import { getMilestonesQuery } from '../components/Milestones/hooks/queries';
import { organizationsQuery } from '../components/Organizations/hooks/queries';
import {
  getActiveProjectBannerQuery,
  getProjectBannersQuery,
} from '../components/ProjectProperties/PrintHeader/hooks/queries';
import { projectsFilterOptionsQuery } from '../components/ProjectsList/hooks/queries';
import { risksCountsQuery, risksQuery } from '../components/RisksRoute/IndexRoute/hooks/queries';
import { riskHistoryQuery, riskQuery } from '../components/RisksRoute/RiskRoute/hooks/queries';
import {
  canApplyScenarioQuery,
  loadOrCreateScenarioSandboxByMilestoneIDQuery,
} from '../components/Scenarios/hooks/queries';
import { loadScenariosSummaryByMilestoneIDQuery } from '../components/Scenarios/Library/hooks/queries';
import { timelineQuery } from '../components/Timeline/hooks/queries';
import {
  isTimelineOptOutQuery,
  loadTimelineQuery,
} from '../components/TimelineRoute/Timeline/hooks/queries';
import {
  analyticsPropertiesQuery,
  categoryCombinationsQuery,
  companyProjectTypes,
  currentCollaborator,
  estimateQuery,
  getProjectCompaniesQuery,
  getProjectPropsQuery,
  listProjectCategorizationsQuery,
  loadProjectRoleQuery,
  loadProjectRolesQuery,
  milestoneCostReportsQuery,
  standardCategorizationsQuery,
} from '../hooks/queries';
import { itemIntegrationObjectsQuery } from '../hooks/useItemIntegrationObjectsQuery';
import { itemsIntegrationObjectsCountsQuery } from '../hooks/useItemsIntegrationObjectsCountsQuery';
import { isNonNullable } from '../utilities/types';

import { TimelineActivityType } from './gqlEnumsBe';

/* 
  We're disabling the above rule because using a non-null-assertion feels like it's the closest
  to our knowledge and intentions. Another approach we could've taken is filtering every array 
  with `.filter(isNonNullable)` but that would seem to suggest that we think getOperationName may
  actually return null for some of our queries. Given that we're feeding a string literal into
  getOperationName, we know the operation name will be defined so patting TS on the head with a !
  feels appropriate here.
*/

export const REFETCH_NAV = [getOperationName(milestoneCostReportsQuery)!];

export const ITEM_COST = [
  getOperationName(itemsListTotalCostQuery)!,
  getOperationName(itemCostQuery)!,
  getOperationName(itemCurrentCostQuery)!,
];

export const REFETCH_PROJECT_ASSETS = [getOperationName(projectAssetsQuery)!];
export const REFETCH_ASSETS = [getOperationName(assetsQuery)!];

export const REFETCH_SCENARIO_SANDBOX = [
  getOperationName(loadOrCreateScenarioSandboxByMilestoneIDQuery)!,
  getOperationName(loadScenariosSummaryByMilestoneIDQuery)!,
  getOperationName(canApplyScenarioQuery)!,
  getOperationName(itemsTreeQuery)!,
  getOperationName(getSharedResourcesQuery)!,
];

export const REFETCH_NEW_ITEM = [
  getOperationName(getMilestoneItemCountQuery)!,
  getOperationName(getMilestonesQuery)!,
  getOperationName(itemHistoryQuery)!,
  getOperationName(itemsTreeQuery)!,
  getOperationName(itemsListQuery)!,
  getOperationName(milestoneCostReportsQuery)!,
  getOperationName(minimalCostReportsQuery)!,
  getOperationName(loadOrCreateScenarioSandboxByMilestoneIDQuery)!,
  getOperationName(timelineQuery)!,
  getOperationName(costTrendlineEventsQuery)!,
  ...ITEM_COST,
];

export const REFETCH_ITEM_ASSETS = [getOperationName(itemHistoryQuery)!];

export const REFETCH_ITEM_ATTACHMENTS = [
  getOperationName(itemAttachmentsQuery)!,
  getOperationName(itemHistoryQuery)!,
  getOperationName(itemQuery)!,
];

export const REFETCH_MILESTONE_ATTACHMENTS = [
  getOperationName(milestoneAttachmentsQuery)!,
  getOperationName(getMilestoneQuery)!,
];

export const REFETCH_ITEM_HISTORY = [getOperationName(itemHistoryQuery)!];

export const REFETCH_NEW_COST_MODE = [
  getOperationName(itemHistoryQuery)!,
  getOperationName(milestoneCostReportsQuery)!,
  getOperationName(minimalCostReportsQuery)!,
];

export const REFETCH_COMPANIES = [getOperationName(getProjectCompaniesQuery)!].filter(
  isNonNullable
);

export const REFETCH_CHANGE_MILESTONE_EVENTS = [
  getOperationName(getMilestoneItemCountQuery)!,
  getOperationName(itemHistoryQuery)!,
  getOperationName(milestoneCostReportsQuery)!,
  getOperationName(minimalCostReportsQuery)!,
];

export const REFETCH_ITEM = [
  ...REFETCH_CHANGE_MILESTONE_EVENTS,
  getOperationName(loadOrCreateScenarioSandboxByMilestoneIDQuery)!,
  getOperationName(itemQuery)!,
];

export const REFETCH_CHANGE_MILESTONE_EVENTS_LIST = [
  ...REFETCH_CHANGE_MILESTONE_EVENTS,
  getOperationName(itemsListQuery)!,
  getOperationName(itemsTreeQuery)!,
  ...ITEM_COST,
];

export const REFETCH_CHANGE_ITEM = [
  ...REFETCH_CHANGE_MILESTONE_EVENTS_LIST,
  getOperationName(loadOrCreateScenarioSandboxByMilestoneIDQuery)!,
  getOperationName(itemQuery)!,
];

export const REFETCH_REMOVE_ITEM_MILESTONE_HISTORY = [
  getOperationName(itemsListQuery)!,
  getOperationName(itemsTreeQuery)!,
  getOperationName(getMilestoneItemCountQuery)!,
  getOperationName(itemQuery)!,
];

export const REFETCH_ITEM_INTEGRATIONS = [
  getOperationName(itemIntegrationObjectsQuery)!,
  getOperationName(itemsIntegrationObjectsCountsQuery)!,
  getOperationName(itemHistoryQuery)!,
];

export const REFETCH_QUANTITY = [
  getOperationName(getMilestoneQuantitiesQuery)!,
  getOperationName(getProjectUnitsQuery)!,
  getOperationName(estimateQuery)!,
  getOperationName(milestoneCostReportsQuery)!,
];

export const REFETCH_QUANTITY_NEW_ESTIMATE = [
  getOperationName(getMilestoneQuantitiesQuery)!,
  getOperationName(getProjectUnitsQuery)!,
];

export const REFETCH_CHANGE_CATEGORIZATION = [
  getOperationName(listProjectCategorizationsQuery)!,
  getOperationName(currentCollaborator)!,
];

export const REFETCH_REMOVE_CATEGORIZATION = [
  getOperationName(listProjectCategorizationsQuery)!,
  getOperationName(loadCollaboratorsQuery)!,
  getOperationName(currentCollaborator)!,
  getOperationName(standardCategorizationsQuery)!,
];

export const REFETCH_ADD_CATEGORIZATION = [
  getOperationName(listProjectCategorizationsQuery)!,
  getOperationName(standardCategorizationsQuery)!,
];

export const REFETCH_DISABLE_CATEGORIZATION = [
  getOperationName(loadCollaboratorsQuery)!,
  getOperationName(currentCollaborator)!,
];

export const REFETCH_COMPANY_PROJECT_TYPES = [getOperationName(companyProjectTypes)!];

// ToDo: Check if other queries need to be added / remove once backend is ready
export const REFETCH_DEPRECATE_ITEM = [
  getOperationName(getMilestoneItemCountQuery)!,
  getOperationName(itemsTreeQuery)!,
  getOperationName(itemsListQuery)!,
  getOperationName(milestoneCostReportsQuery)!,
  ...ITEM_COST,
];

export const refetchProjectBannersInformation = (projectID: UUID) => [
  { query: getProjectBannersQuery, variables: { projectID } },
  { query: getActiveProjectBannerQuery, variables: { projectID } },
];

export const refetchActiveProjectBanner = (projectID: UUID) => [
  { query: getActiveProjectBannerQuery, variables: { projectID } },
];

export const refetchChangeProjectMetadata = (projectID: UUID) => [
  { query: getProjectPropsQuery, variables: { id: projectID } },
];

export const refetchPermissions = (projectID: UUID, roleID: UUID) => [
  { query: loadProjectRoleQuery, variables: { projectID, roleID } },
  { query: loadProjectRolesQuery, variables: { projectId: projectID } },
  { query: currentCollaborator, variables: { projectID } },
  { query: analyticsPropertiesQuery, variables: { projectId: projectID } },
];

export const refetchItem = (id: string, costMode: CostMode) => ({
  query: itemQuery,
  variables: { id, costMode },
});

export const refetchItemBookmarks = (itemID: string) => ({
  query: itemBookmarksQuery,
  variables: { itemID },
});

export const refetchItemEstimate = (estimateID: string, costMode: CostMode) => [
  { query: estimateQuery, variables: { estimateID, costMode } },
];

export const refetchMilestoneQuantities = (milestoneID: UUID) => [
  { query: getMilestoneQuantitiesQuery, variables: { milestoneID } },
  getOperationName(categoryCombinationsQuery)!,
  getOperationName(milestoneCostReportsQuery)!,
];

export const REFETCH_IN_APP_NOTIFICATIONS_BADGE = [
  getOperationName(hasUnreadInAppNotificationsQuery)!,
];

export const REFETCH_IN_APP_NOTIFICATIONS = [
  getOperationName(loadInAppNotificationsQuery)!,
  getOperationName(loadInAppNotificationsCountQuery)!,
  getOperationName(hasUnreadInAppNotificationsQuery)!,
];

export const REFETCH_PROJECT_TEMPLATE = [getOperationName(projectTemplatesQuery)!];

export const USER_SOURCES = [getOperationName(userSourcesQuery)! as InternalRefetchQueryDescriptor];

export const REFETCH_COMPANY_STATS = [
  getOperationName(getCompanyProjectStatsQuery)!,
  getOperationName(projectsFilterOptionsQuery)!,
  getOperationName(loadCollaboratorsMultipleProjects)!,
  getOperationName(companyProjectRolesQuery)!,
];

export const refetchStatsCollaborators = (projectIDs: UUID[]) => [
  {
    query: loadCollaboratorsMultipleProjects,
    variables: { projectIDs },
  },
  getOperationName(getCompanyProjectStatsQuery)!,
];

export const REFETCH_COMPANY_CATEGORIZATIONS = [getOperationName(companyCategorizationsQuery)!];

export const REFETCH_TIMELINE_EVENTS_PHASES = (projectID: UUID, types: TimelineActivityType[]) => [
  {
    query: timelineQuery,
    variables: {
      input: { projectID, types },
    },
  },
];

export const REFETCH_PROJECT_ROLES = [
  getOperationName(loadProjectRolesQuery)!,
  getOperationName(loadCollaboratorsQuery)!,
];

export const REFETCH_ORGS = [getOperationName(organizationsQuery)!];

export const REFETCH_ALERTS = [getOperationName(getAlertsQuery)!];

export const REFETCH_TIMELINE = [getOperationName(loadTimelineQuery)!];
export const REFETCH_IS_TIMELINE_OPT_OUT = [getOperationName(isTimelineOptOutQuery)!];

export const REFETCH_ALERTS_PROJECT_STATUS = [
  getOperationName(getAlertsProjectStatusSettingQuery)!,
];

export const REFETCH_RISKS = [getOperationName(risksQuery)!, getOperationName(risksCountsQuery)!];
export const REFETCH_RISK = [getOperationName(riskQuery)!, getOperationName(riskHistoryQuery)!];
