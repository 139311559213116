import { ReactNode, createContext, useContext, useMemo } from 'react';

import { useReactiveVar } from '@apollo/client';

import { usePreviewSettingsVar } from '../../api/apollo/reactiveVars';
import { NS_OWNER_COSTS_V2 } from '../../features';
import { CostDisplay, MarkupMode } from '../../generated/graphql';
import { useHasFeature } from '../../hooks/useHasFeature';
import {
  DefaultCostMode,
  getCostModePermissions,
  projectsCostModesVar,
  setNewProjectCostMode,
} from '../../utilities/costMode';
import { UsePermissions } from '../../utilities/permissions/types';
import usePermissions from '../../utilities/permissions/usePermissions';
import { useProjectID } from '../../utilities/routes/params';

// TODO: import from ../../generated/graphql after includeOwnerCosts is a required field
type CostMode = {
  costDisplay: CostDisplay;
  includeOwnerCosts: boolean;
  markupMode: MarkupMode;
};

const CostModeContext = createContext<CostMode | undefined>(undefined);
CostModeContext.displayName = 'CostModeContext';

export const CostModeProvider = (props: { children: ReactNode; projectID: UUID | undefined }) => {
  const permissions = usePermissions({ projectID: props.projectID });
  const costMode = useCostModePermissions(permissions, props.projectID);
  return (
    <CostModeContext.Provider value={costMode}>
      {/* Child elements with Default Cost Mode or Project Permissions Based Cost Mode */}
      {props.children}
    </CostModeContext.Provider>
  );
};

/**
 * useCostMode hook
 * @returns CostMode
 */
export function useCostMode(): CostMode {
  const costMode = useContext(CostModeContext);
  // This is a fallback in case we use useCostMode() outside of CostModeProvider
  // use cases are storybook stories and unit tests.
  return costMode ?? DefaultCostMode;
}

const useCostModePermissions = (permissions: UsePermissions, customProjectID?: UUID) => {
  const currentProjectID = useProjectID();
  const projectID = customProjectID || currentProjectID;
  const projectsCostModes = useReactiveVar(projectsCostModesVar);
  const hasOwnerCostFeature = useHasFeature(NS_OWNER_COSTS_V2);
  const preview = usePreviewSettingsVar();

  const { markupMode, costDisplay, includeOwnerCosts } = getCostModePermissions(
    permissions,
    projectsCostModes,
    projectID,
    hasOwnerCostFeature
  );

  // Update LocalStorage if current value is different that what is stored there (and not in preview mode)
  const inPreviewMode = Boolean(preview.userID || preview.roleID);
  if (
    !permissions.loading &&
    !inPreviewMode &&
    projectID &&
    (projectsCostModes[projectID]?.markupMode !== markupMode ||
      projectsCostModes[projectID]?.costDisplay !== costDisplay)
  ) {
    const costMode = { costDisplay, markupMode, includeOwnerCosts };
    setNewProjectCostMode(projectID, costMode, projectsCostModes);
  }

  return useMemo(
    () => ({ markupMode, costDisplay, includeOwnerCosts }),
    [costDisplay, markupMode, includeOwnerCosts]
  );
};

export const updateCostMode = (projectID: UUID, update: Partial<CostMode>) => {
  const projectsCostModes = projectsCostModesVar();
  const prev = projectsCostModes[projectID] ?? DefaultCostMode;
  const newCostMode = { ...prev, ...update };
  setNewProjectCostMode(
    projectID,
    { ...newCostMode, includeOwnerCosts: !!newCostMode.includeOwnerCosts },
    projectsCostModes
  );
};
