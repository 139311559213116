import { useRef } from 'react';
import { useButton } from 'react-aria';

export default function ClearButton(props: { onClick: () => void }) {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(
    {
      'aria-label': 'clear',
      onPress: props.onClick,
    },
    ref
  );

  return (
    <button {...buttonProps} ref={ref} className="outline-none type-body3 link">
      Clear
    </button>
  );
}
