import { useMemo } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- We'll never know all the args here.
function useMemoWrapper<F extends (...args: any[]) => ReturnType<F>>(
  func: F,
  ...args: Parameters<F>
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- We'll never know all the args, that's the point.
  return useMemo<ReturnType<F>>(() => func(...args), [...args]);
}

export default useMemoWrapper;
