import { commonUserFields, gql } from '../../../../api/graphqlFragments';
import { listCompanyUserFieldsQuery } from '../../../login/hooks/queries';

export const risksQuery = gql`
  query Risks(
    $projectID: UUID!
    $riskType: RiskType!
    $filters: RisksFilters!
    $costDisplay: CostDisplay!
  ) {
    risks(
      projectID: $projectID
      riskType: $riskType
      filters: $filters
      costDisplay: $costDisplay
    ) {
      id
      projectID
      type
      number
      name
      description
      descriptionStyled
      responsePlan
      responsePlanStyled
      romCost
      status
      riskScore
      impact
      likelihood
      assignee {
        ...commonUserFields
      }
      updatedBy {
        ...commonUserFields
      }
      createdBy {
        ...commonUserFields
      }
      updatedAt
      createdAt
      linkedItemIDs
    }
  }
  ${commonUserFields}
`;

export const risksCountsQuery = gql`
  query RisksCounts($projectID: UUID!) {
    risksCounts(projectID: $projectID) {
      totalProjectRisks
      totalCompanyRisks
    }
  }
`;

export const createRiskMutation = gql`
  mutation CreateRisk($projectID: UUID!, $input: CreateRiskInput!, $riskType: RiskType!) {
    createRisk(projectID: $projectID, input: $input, riskType: $riskType) {
      id
      projectID
      type
      number
      name
      description
      descriptionStyled
      responsePlan
      responsePlanStyled
      status
      riskScore
      impact
      likelihood
      assignee {
        ...commonUserFields
      }
      updatedBy {
        ...commonUserFields
      }
      createdBy {
        ...commonUserFields
      }
      updatedAt
      createdAt
      romCost
    }
  }
  ${commonUserFields}
`;

export const companyProjectCollaborators = gql`
  query companyProjectCollaborators($companyID: UUID!, $projectID: UUID!) {
    companyProjectCollaborators(companyID: $companyID, projectID: $projectID) {
      ...listCompanyUserFieldsQuery
    }
  }
  ${listCompanyUserFieldsQuery}
`;
