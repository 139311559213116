import { useLayoutEffect, useRef, useState } from 'react';

/**
 * Custom hook to determine if an element is truncated (i.e., its content
 * overflows its visible area), in order to conditionally render a tooltip.
 */
export default function useIsElementTruncated<T extends HTMLElement>() {
  const ref = useRef<T>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  useLayoutEffect(() => {
    if (ref.current) {
      setIsTruncated(ref.current.scrollHeight > ref.current.clientHeight);
    }
  }, [ref]);

  return { ref, isTruncated };
}
