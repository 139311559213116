import { ComponentProps } from 'react';

import Icon from '../Icon/Icon';
import { Tooltip } from '../Tooltip';

type Props = {
  content?: ComponentProps<typeof Tooltip>['content'];
};

export default function Hint(props: Props) {
  if (!props.content) return null;
  return (
    <Tooltip content={props.content}>
      <Icon className="cursor-default" name="help" size="sm" />
    </Tooltip>
  );
}
