import { ComponentProps, HTMLAttributes, forwardRef, useRef } from 'react';
import { useTextField } from 'react-aria';

import composeRefs from '@seznam/compose-react-refs';

import { StrictUnion } from '../../../utilities/types';
import ClearButton from '../ClearButton/ClearButton';
import Hint from '../Hint/Hint';
import { validateDataCy } from '../utils/data-cy';

type BaseTextAreaProps = {
  id?: string;
  'data-cy'?: string;
  errorMessage?: string;
  hint?: ComponentProps<typeof Hint>['content'];
  isDisabled?: boolean;
  isResizable?: boolean;
  onBlur?: HTMLAttributes<HTMLTextAreaElement>['onBlur'];
  onChange?: (newValue: string) => void;
  onClear?: () => void;
  onKeyDown?: Parameters<typeof useTextField>[0]['onKeyDown'];
  placeholder?: string;
  value?: string;
};

type LabelledTextAreaProps = BaseTextAreaProps & {
  'aria-label'?: string;
  label: string;
};

type UnlabelledTextAreaProps = BaseTextAreaProps & {
  'aria-label': string;
  label?: string;
};

type TextAreaProps = StrictUnion<LabelledTextAreaProps | UnlabelledTextAreaProps>;

export default forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(props, forwardedRef) {
    const { errorMessage } = props;
    const ref = useRef<HTMLTextAreaElement>(null);
    const { labelProps, inputProps, errorMessageProps } = useTextField(
      {
        ...props,
        onBlur: props.onBlur,
        onKeyDown: (e) => {
          props.onKeyDown?.(e);
        },
        isDisabled: Boolean(props.isDisabled),
        ...(errorMessage ? { validationState: 'invalid', 'aria-errormessage': errorMessage } : {}),
        inputElementType: 'textarea',
      },
      ref
    );

    const hasLabel = Boolean(props.label);
    const hasClearButton = Boolean(props.onClear && !props.isDisabled);

    validateDataCy(props['data-cy'], 'text-area');

    return (
      <div className="w-full">
        <div className="flex flex-col gap-0.5">
          {(hasLabel || hasClearButton) && (
            <div className="flex items-center justify-between gap-1">
              <div className="flex items-center gap-1">
                <label {...labelProps} className="text-type-primary type-label">
                  {props.label}
                </label>
                {props.hint && <Hint content={props.hint} />}
              </div>
              {hasClearButton && props.onClear && <ClearButton onClick={props.onClear} />}
            </div>
          )}
          <textarea
            {...inputProps}
            ref={composeRefs(ref, forwardedRef)}
            className={`block h-20 rounded-md border bg-background-primary p-2 text-type-primary type-body1 placeholder:text-type-inactive focus:outline disabled:bg-button-inactive disabled:text-type-inactive ${
              errorMessage ? 'border-type-error' : ''
            } ${props.isResizable ? '' : 'resize-none'}`}
            data-cy={props['data-cy']}
          />
          {errorMessage && (
            <div {...errorMessageProps} className="cursor-default text-type-error type-label">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    );
  }
);
