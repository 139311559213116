import { useQuery } from '@apollo/client';

import {
  InputMaybe,
  ItemsFilterBy,
  ItemsTreeQuery,
  ItemsTreeQueryVariables,
} from '../../generated/graphql';
import { useQuery as useMountAwareQuery } from '../../hooks/useMountAwareQuery';
import { MountPolicy } from '../../hooks/usePolicyOnFirstMount';
import { useCostMode } from '../contexts/cost-mode';
import { GetGroupBysInput } from '../CostReport/hooks/useMilestoneCostReportQuery';

import { itemsTreeQuery } from './hooks/itemsListQuery';

export const emptyItemsTree: ItemsTree = {
  branches: [],
  items: [],
  level: 0,
  totalItems: 0,
  orderedItemIDs: [],
};

export const useItemsTreeQuery = (
  itemsSortBy: ItemsSortBy,
  filters: ItemsFilterBy,
  itemsGroupBy: InputMaybe<string> | undefined,
  milestoneID: InputMaybe<UUID> | undefined,
  activityID: InputMaybe<UUID>,
  projectID: UUID,
  meetingID: InputMaybe<UUID> | undefined,
  viewFilter: ViewFilterInput,
  groupBys: DisplayGroupBy[] = [],
  skipQuery?: boolean
) => {
  // in some older reports created before 10/22/21 all creators is saved as ["All"]
  // instead of an empty list like we currently do
  const itemsFilterBy = filters;
  if (filters.creators.length === 1 && filters.creators[0].toLowerCase() === 'all') {
    itemsFilterBy.creators = [];
  }

  return useMountAwareQuery<ItemsTreeQuery, ItemsTreeQueryVariables>(
    itemsTreeQuery,
    {
      variables: {
        itemsSortBy,
        itemsFilterBy,
        itemsGroupBy,
        milestoneID,
        activityID,
        projectID,
        meetingID,
        costMode: useCostMode(),
        viewFilter,
        groupByItems: GetGroupBysInput(groupBys),
      },
      skip: skipQuery || !projectID,
    },
    MountPolicy.FETCH_ON_VARS
  );
};

/** @deprecated useCachedItemsTreeQuery
 * is used to produce navigation for
 * the item details nav links. It is used to get the
 * data from the cache only.
 *
 * It will need to be kept up to date with the useItemsTreeQuery
 * param inputs
 */
export const useCachedItemsTreeQuery = (
  itemsSortBy: ItemsSortBy,
  filters: ItemsFilterBy,
  itemsGroupBy: InputMaybe<string> | undefined,
  milestoneID: InputMaybe<UUID> | undefined,
  activityID: InputMaybe<UUID>,
  projectID: UUID,
  meetingID: InputMaybe<UUID> | undefined,
  viewFilter: ViewFilterInput,
  groupBys: DisplayGroupBy[] = [],
  skipQuery?: boolean
) => {
  // in some older reports created before 10/22/21 all creators is saved as ["All"]
  // instead of an empty list like we currently do
  const itemsFilterBy = filters;
  if (filters.creators.length === 1 && filters.creators[0].toLowerCase() === 'all') {
    itemsFilterBy.creators = [];
  }

  return useQuery<ItemsTreeQuery, ItemsTreeQueryVariables>(itemsTreeQuery, {
    variables: {
      itemsSortBy,
      itemsFilterBy,
      itemsGroupBy,
      milestoneID,
      activityID,
      projectID,
      meetingID,
      costMode: useCostMode(),
      viewFilter,
      groupByItems: GetGroupBysInput(groupBys),
    },
    fetchPolicy: 'cache-only',
    skip: skipQuery || !projectID,
  });
};
