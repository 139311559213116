import { forwardRef } from 'react';

import { StrictUnion } from '../../../utilities/types';
import { TooltipTriggerProps, pickTooltipTriggerProps } from '../Tooltip';

import { JoshIconType, getJoshIcon } from './josh-icons/utils';
import { MaterialIconType } from './material-ui-icons/utils';

type BaseProps = TooltipTriggerProps & {
  className?: string;
  /** Size to use, defaults to `md`  */
  size?: 'sm' | 'md' | 'lg';
};

type MaterialIconProps = BaseProps & {
  /** Name of the material-ui icon per https://fonts.google.com/icons?icon.set=Material+Icons&icon.style=Outlined */
  name: MaterialIconType;
  /** Style variant to use, defaults to `outlined`  */
  type?: 'filled' | 'outlined';
};

type JoshIconProps = BaseProps & {
  name: JoshIconType;
};

type Props = StrictUnion<MaterialIconProps | JoshIconProps>;

const sizeClasses = {
  sm: '!icon-sm',
  md: '!icon-md',
  lg: '!icon-lg',
};

const typeClasses = {
  filled: 'material-icon-font icon-filled',
  outlined: 'material-icon-font icon-outlined',
};

export default forwardRef<HTMLSpanElement, Props>(function Icon(props, ref) {
  const JoshIcon = getJoshIcon(props.name);
  return (
    <span
      aria-hidden="true"
      className={[
        'relative',
        props.className,
        typeClasses[props.type ?? 'outlined'],
        sizeClasses[props.size ?? 'md'],
      ].join(' ')}
      role="img"
      {...pickTooltipTriggerProps(props)}
      ref={ref}
    >
      {JoshIcon ? <JoshIcon /> : props.name}
    </span>
  );
});
